import { AxiosResponse } from 'axios';

import { postWithApiKey } from './AxiosHelper';

const SERVICES_URL = process.env.OCA_SERVICES_URL;

export interface FeedbackRequest {
  appId: string | undefined;
  rating: number | undefined;
  canContact: boolean | undefined;
}

export const postFeedback = async (request: FeedbackRequest): Promise<any> => {
  const response = await postWithApiKey<null, AxiosResponse<null, FeedbackRequest>, FeedbackRequest>(
    `${SERVICES_URL}/api/v1/customer-feedback`,
    request
  );
  return response.data;
};
