import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../../models/SubmitApplication';
import SensitiveContent from '../../../../common/SensitiveContent';

export default function PersonalInformationReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  const ssn = getValues('guarantorInfo.personalInformation.ssn');
  const ssnMasked = '*****' + ssn?.substring(ssn.length - 4, ssn.length);

  return (
    <div className="read-only-wrapper">
      <Grid className="gap-y-2">
        <Grid.Item
          s={12}
          m={6}
          l={3}>
          <div className="font-weight-bold font-xsmall my-2">First Name</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.personalInformation.firstName')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={3}>
          <div className="font-weight-bold font-xsmall my-2">Last Name</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.personalInformation.lastName')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={3}>
          <div className="font-weight-bold font-xsmall my-2">Phone Number</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.personalInformation.phoneNumber')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={6}>
          <div className="font-weight-bold font-xsmall my-2">Email Address</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.personalInformation.email')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={3}>
          <div className="font-weight-bold font-xsmall my-2">Date of Birth</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.personalInformation.dob')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={6}>
          <div className="font-weight-bold font-xsmall my-2">Social Security Number</div>
          <SensitiveContent className="font-xsmall my-2">{ssnMasked}</SensitiveContent>
        </Grid.Item>
      </Grid>
    </div>
  );
}
