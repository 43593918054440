import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableButton } from '@fcamna/aem-library';
import { DecrementStepper, IncrementSelector, IncrementStepper, NumberInputField } from '@fcamna/react-library';
import { useFormContext, useWatch } from 'react-hook-form';

import { MSRP_LIMITS, QUANTITY_LIMITS } from '../../../../constants/vehicle.constant';
import useVehicleMSRPCalculator from '../../../../hooks/useVehicleMSRPCalculator';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import useCocaStore from '../../../../store';
import styles from './VehicleSelector.module.scss';

interface VehicleSelectorProps {
  vehicleIndex: number;
  handleRemoveVehicle: () => void;
  handleDecreaseQuantity?: () => void;
}

export default function VehicleSelector(props: Readonly<VehicleSelectorProps>) {
  const { vehicleIndex, handleRemoveVehicle, handleDecreaseQuantity } = props;
  const vehicleWatcher = useWatch({ name: 'vehicles' });
  const { totalMSRP, totalVehicles } = useVehicleMSRPCalculator();

  const { getValues, setValue } = useFormContext<SubmitApplicationFormData>();
  const quantity = getValues(`vehicles.${vehicleIndex}.quantity`) ?? 1;
  const isAuthorView = AuthoringUtils.isInEditor();
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);

  const handleChange = (value: number) => {
    setValue(`vehicles.${vehicleIndex}.quantity`, value);
    if (quantity > value) {
      if (handleDecreaseQuantity) handleDecreaseQuantity();
    }
  };

  const isMaxMSRPLimitReached = () => {
    return totalMSRP > MSRP_LIMITS.PILOT_DEALER_MAX_LIMIT;
  };

  const removeVehicle = () => {
    handleRemoveVehicle();
  };

  return (
    <div className={styles.quantityWrapper}>
      <IncrementSelector
        isDisabled={vehicleWatcher[vehicleIndex]?.data == null}
        labelProp={{ label: 'Quantity' }}
        value={quantity}
        isInvalid={isPilotDealer && isMaxMSRPLimitReached()}
        onChange={handleChange}>
        <DecrementStepper isDisabled={quantity == QUANTITY_LIMITS.MIN} />
        <NumberInputField />
        <IncrementStepper isDisabled={totalVehicles >= QUANTITY_LIMITS.MAX} />
      </IncrementSelector>
      <div></div>
      <div>
        {(vehicleWatcher.length > 1 || isAuthorView) && (
          <div>
            <EditableButton
              name="remove-vehicle"
              onClick={removeVehicle}
            />
          </div>
        )}
      </div>
    </div>
  );
}
