import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../../utils/aem/aemRoute';

export default function DealerReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_DEALER_READONLY}`}>
      <div className="read-only-wrapper">
        <Grid>
          <Grid.Item
            l={6}
            m={12}>
            <div className="font-weight-bold font-xsmall my-2">Dealer</div>
            <div className="font-xsmall my-2">{getValues('dealer.data.name')}</div>
          </Grid.Item>
        </Grid>
      </div>
    </AEMSubRoute>
  );
}
