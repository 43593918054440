export const MSRP_LIMITS = Object.freeze({
  PILOT_DEALER_LIMIT: 350000,
  PILOT_DEALER_MAX_LIMIT: 649990,
  NON_PILOT_DEALER_LIMIT: 150000,
  NON_PILOT_DEALER_MAX_LIMIT: 250000
});

export const QUANTITY_LIMITS = Object.freeze({
  MAX: 10,
  CLOC: 6,
  MIN: 1
});
