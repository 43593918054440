import { InLineAlert } from '@fcamna/react-library';
import { useEffect, useState } from 'react';

interface Props {
  title: string;
}

export default function ModifySuccess(props: Props) {
  const { title } = props;
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsVisible(false);
    }, 3000);
  }, []);

  return (
    isVisible && (
      <div className="modify-success pb-2">
        <InLineAlert
          titleProp={{ title: `${title} updated successfully.`, headingLevel: 'h6' }}
          variant="success"
          onClickClose={() => setIsVisible(false)}
        />
      </div>
    )
  );
}
