import { yupResolver } from '@hookform/resolvers/yup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { BrowserRouter } from 'react-router-dom';

import ErrorFallBack from '../../components/ErrorFallBack';
import { AppContextProvider } from '../../context/appContext';
import { getDefaultVehicle } from '../../models/formData/VehicleFormData';
import { SubmitApplicationFormData, submitApplicationSchema } from '../../models/SubmitApplication';
import Router from '../../routes/Router';
import GlobalLinks from '../../utils/GlobalLinks';
import GlobalScripts from '../../utils/GlobalScripts';
import { SessionStorageUtils } from '../../utils/SessionStorageUtils';

export const useFormOptions: UseFormProps<SubmitApplicationFormData | any> = {
  resolver: yupResolver(submitApplicationSchema),
  mode: 'onBlur',
  defaultValues: {
    isExpress: true,
    quantity: 1,
    vehicles: [getDefaultVehicle()]
  }
};

function Coca() {
  const queryClient = new QueryClient();
  const methods = useForm<SubmitApplicationFormData>(useFormOptions);
  const {
    reset,
    formState: { isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepDefaultValues: true });
    }
  }, [reset, isSubmitSuccessful]);
  /**
   * Updates the form with exiting formValues in the session storage.
   */
  useEffect(() => {
    const existingFormData = SessionStorageUtils.getParseItem('formData');
    existingFormData && reset(existingFormData);
  }, [reset]);

  useEffect(() => {
    document.documentElement.lang = 'en';
  }, []);

  return (
    <>
      <GlobalLinks />
      <GlobalScripts />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <FormProvider {...methods}>
            <AppContextProvider>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <Router />
              </ErrorBoundary>
            </AppContextProvider>
          </FormProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
}

export default Coca;
