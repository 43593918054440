import { Card, Grid } from '@fcamna/react-library';

import { GuarantorInfoFormData } from '../../../models/formData/GuarantorInfoFormData';
import { Page } from '../../../models/FormPage';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';
import EmploymentInformationReview from './EmployementInformationReview/EmploymentInformationReview';
import PersonalInformationReview from './PersonalInformationReview/PersonalInformationReview';
import ResidenceInformationReview from './ResidenceInformationReview/ResidenceInformationReview';

interface Props {
  guarantorInformation: GuarantorInfoFormData;
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
  hasGuarantor: boolean;
}

export default function GuarantorReview(props: Readonly<Props>) {
  const { guarantorInformation, handleModify, updateStep, setUpdatedStep, hasGuarantor } = props;

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title="Guarantor Information"
        handleModify={() => {
          handleModify(Page.GUARANTOR_INFORMATION);
          setUpdatedStep(Page.GUARANTOR_INFORMATION);
        }}
        currentUpdatedStep={updateStep === Page.GUARANTOR_INFORMATION}
      />
      {!hasGuarantor && (
        <Grid className="mt-3 gap-y-2 color-neutral-600">
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Would you like to add a Guarantor</div>
            <div className="font-xsmall my-2">No</div>
          </Grid.Item>
        </Grid>
      )}
      {hasGuarantor && (
        <Grid className="mt-3 gap-y-2 color-neutral-600">
          <PersonalInformationReview personalInformation={guarantorInformation?.personalInformation} />
          <ResidenceInformationReview residenceInformation={guarantorInformation?.residenceInformation} />
          <EmploymentInformationReview employmentInformation={guarantorInformation?.employmentInformation} />
        </Grid>
      )}

      <ReviewFooter
        handleModify={() => {
          handleModify(Page.GUARANTOR_INFORMATION);
          setUpdatedStep(Page.GUARANTOR_INFORMATION);
        }}
      />
    </Card>
  );
}
