import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../../models/SubmitApplication';
import SensitiveContent from '../../../../common/SensitiveContent';

export default function ResidenceInformationReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  const CITY_STATE =
    getValues('guarantorInfo.residenceInformation.city') +
    ', ' +
    getValues('guarantorInfo.residenceInformation.state.label') +
    ' ' +
    getValues('guarantorInfo.residenceInformation.zip');

  return (
    <div className="read-only-wrapper">
      <Grid className="gap-y-2">
        <Grid.Item
          s={12}
          m={6}
          l={6}
          xl={6}>
          <div className="font-weight-bold font-xsmall my-2">Address</div>
          <SensitiveContent className="font-xsmall mt-2">{getValues('guarantorInfo.residenceInformation.streetAddress')}</SensitiveContent>
          <SensitiveContent className="font-xsmall mb-2">{CITY_STATE}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={3}
          xl={3}>
          <div className="font-weight-bold font-xsmall my-2">Residence Type</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.residenceInformation.residenceType.label')}
          </SensitiveContent>
        </Grid.Item>
        <Grid.Item
          s={12}
          m={6}
          l={3}
          xl={3}>
          <div className="font-weight-bold font-xsmall my-2">Rent/Mortgage</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.residenceInformation.rentMortgage.formattedValue')}
          </SensitiveContent>
        </Grid.Item>
      </Grid>
    </div>
  );
}
