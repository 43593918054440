import { Checkbox, CheckboxStateValues, Grid } from '@fcamna/react-library';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppContext } from '../../../../context/appContext';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import AEMInput from '../../../common/AEM/Input/AEMInput';
import SsnInput from '../../../common/SsnInput';

export default function PersonalInformation() {
  const { getValues, setValue, clearErrors } = useFormContext<SubmitApplicationFormData>();
  const { personalSameAsContact, setPersonalSameAsContact } = useAppContext();

  const onEnable = () => {
    setValue('guarantorInfo.personalInformation.firstName', getValues('applicantInformation.firstName'));
    setValue('guarantorInfo.personalInformation.lastName', getValues('applicantInformation.lastName'));
    setValue('guarantorInfo.personalInformation.phoneNumber', getValues('applicantInformation.phoneNumber'));
    setValue('guarantorInfo.personalInformation.email', getValues('applicantInformation.emailAddress'));
    setPersonalSameAsContact(CheckboxStateValues.CHECKED);
  };

  const onDisable = () => {
    setValue('guarantorInfo.personalInformation.firstName', '');
    setValue('guarantorInfo.personalInformation.lastName', '');
    setValue('guarantorInfo.personalInformation.phoneNumber', '');
    setValue('guarantorInfo.personalInformation.email', '');
    setPersonalSameAsContact(CheckboxStateValues.UNCHECKED);
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  return (
    <div>
      <div className="cf-field">
        <Checkbox
          label="Same as Contact information"
          checkboxValue={personalSameAsContact}
          onChange={(_e, value) => {
            if (value === CheckboxStateValues.CHECKED) {
              onEnable();
              clearErrors();
            } else {
              onDisable();
            }
            setPersonalSameAsContact(value);
          }}
        />
      </div>
      <Grid className="gap-y-2">
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.personalInformation.firstName"
              aemName="guarantor-first-name"
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.personalInformation.lastName"
              aemName="guarantor-last-name"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.personalInformation.phoneNumber"
              aemName="guarantor-phone-number"
              mask="(___) ___-____"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={8}
          l={10}
          m={8}
          s={12}>
          <div className="cf-field mb-4">
            <AEMInput
              type="text"
              aemName="guarantor-email"
              name="guarantorInfo.personalInformation.email"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.personalInformation.dob"
              aemName="guarantor-dob"
              mask="__/__/____"
              placeholder="MM/DD/YYYY"
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <SsnInput
              name="guarantorInfo.personalInformation.ssn"
              aemName="social-security-number"
            />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
}
