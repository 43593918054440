import { Button, Divider, Grid, Pagination } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useEffect, useState } from 'react';

import { useAppContext } from '../../../../context/appContext';
import { Dealer } from '../../../../models/Dealer';
import styles from '../Dealer.module.scss';
import DealerCard from '../DealerCard/DealerCard';

const { Expand } = fordProIcons;

interface DealerCardGridProps {
  dealers: Dealer[];
  selectedDealer: Dealer | undefined;
  onDealerSelect: (dealerData: Dealer | undefined) => void;
}

const FORD_PRO_URL = 'fordpro.com';
const isDealerURL = !window.location?.hostname?.includes(FORD_PRO_URL);

export default function DealerCardGrid({ dealers, selectedDealer, onDealerSelect }: Readonly<DealerCardGridProps>) {
  const isMobile = false;
  const mobileDisplayCount = 3;
  const desktopDisplayCount = 6;
  const [dealerDisplayCount, setDealerDisplayCount] = useState(isMobile ? mobileDisplayCount : desktopDisplayCount);
  const [displayedDealers, setDisplayedDealers] = useState(dealers.slice(0, dealerDisplayCount));
  const { isProvidedDealer } = useAppContext();

  const handlePageChange = (newPage: number) => {
    const dealerIndex = (newPage - 1) * dealerDisplayCount;
    setDisplayedDealers(dealers.slice(dealerIndex, dealerIndex + dealerDisplayCount));
  };

  useEffect(() => {
    setDisplayedDealers(dealers.slice(0, dealerDisplayCount));
  }, [dealers, dealerDisplayCount]);

  useEffect(() => {
    setDealerDisplayCount(isMobile ? mobileDisplayCount : desktopDisplayCount);
  }, [isMobile]);

  return (
    <div>
      <Grid
        data-testid="dealer-grid"
        gutterWidth={20}
        className={styles.dealerGrid}
        style={{ marginBlock: isProvidedDealer ? 0 : 32 }}>
        {displayedDealers.map((dealer, index) => (
          <Grid.Item
            s={12}
            m={12}
            l={4}
            xl={4}
            key={dealer.latitude}>
            <DealerCard
              dealerIndex={index}
              dealerData={dealer}
              isSelected={dealer.id === selectedDealer?.id}
              onSelectHandler={onDealerSelect}
              providedDealer={isProvidedDealer}
              selectedDealer={selectedDealer?.id}
            />
          </Grid.Item>
        ))}
      </Grid>
      {dealers.length > dealerDisplayCount && (
        <>
          {isMobile ? (
            <Button
              size="large"
              onClick={() => {
                setDealerDisplayCount((prev) => prev + mobileDisplayCount);
              }}
              style={{
                color: 'black',
                borderColor: 'black',
                fontSize: 16,
                fontWeight: 'normal',
                textTransform: 'capitalize',
                margin: 4
              }}
              rightIcon={<Expand />}>
              Show More
            </Button>
          ) : (
            <>
              <div className="cf-field divider">
                <Divider />
              </div>
              <div className={!isDealerURL && selectedDealer ? styles.disabledPagination : styles.pagination}>
                <Pagination
                  totalItems={Math.ceil(dealers.length / dealerDisplayCount)}
                  onPageChange={handlePageChange}
                  page={1}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
