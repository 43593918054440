import { Grid } from '@fcamna/react-library';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Cell, Pie, PieChart, Sector } from 'recharts';

import useVehicleMSRPCalculator from '../../../../hooks/useVehicleMSRPCalculator';
import { VehicleFormData } from '../../../../models/formData/VehicleFormData';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import styles from '../VehicleReview.module.scss';

const COLORS = ['#066FEF', '#00095B', '#6B7786', '#388CF2', '#333A7C', '#1f3047', '#033878'];

interface ChartData {
  name: string;
  value: number;
}

const defaultData: ChartData[] = [{ name: 'Undecided', value: 1 }];

const renderActiveShape = (props: any, totalVehicles: number, quantity: number, isExpress: boolean) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  const vehicles = isExpress ? quantity : totalVehicles;

  return (
    <g>
      <foreignObject
        x={cx - 50}
        y={cy - 25}
        width="100"
        height="100">
        <div className="d-flex flex-column justify-center text-center">
          <b className="font-fordmotion font-xxxlarge">{vehicles}</b>
          <p className="font-xsmall font-fordmotion m-0">Application{vehicles > 1 ? 's' : ''}</p>
        </div>
      </foreignObject>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default function PilotDealerVehicleReview() {
  const [chartData, setChartData] = useState<ChartData[]>(defaultData);
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  const { totalVehicles } = useVehicleMSRPCalculator();
  const { vehicles, quantity, isExpress, downPayment } = getValues();

  useEffect(() => {
    if (isExpress) {
      setChartData([{ name: 'Application' + (quantity > 1 ? 's' : ''), value: quantity }]);
    } else {
      const vehicleMap = new Map();

      vehicles.forEach((vehicle: VehicleFormData) => {
        const entry = vehicleMap.get(vehicle.model?.label);
        vehicleMap.set(vehicle.model?.label, entry ? entry + vehicle.quantity : vehicle.quantity);
      });
      const chartData = Array.from(vehicleMap).map(([name, value]) => ({ name, value })) ?? [];

      setChartData(chartData);
    }
  }, [isExpress, quantity, vehicles]);

  return (
    <Grid>
      <Grid.Item
        xl={5}
        l={6}
        m={12}
        s={12}>
        <div className="d-flex justify-center">
          <PieChart
            width={200}
            height={200}>
            <Pie
              data={chartData}
              innerRadius={60}
              outerRadius={78}
              paddingAngle={0.8}
              dataKey="value"
              activeIndex={0}
              activeShape={(props: any) => renderActiveShape(props, totalVehicles, quantity, isExpress)}>
              {chartData.map((data, index) => (
                <Cell
                  key={`cell-${data.name}}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
      </Grid.Item>
      <Grid.Item
        xl={7}
        l={6}
        m={12}
        s={12}>
        <div className="d-flex flex-column justify-center h-100 gap-2 font-xsmall">
          {!isExpress && <span className="color-twilight-1000">Vehicle(s)</span>}
          <table>
            <tbody>
              {!isExpress && (
                <>
                  {chartData.map((data: ChartData, index: number) => (
                    <tr key={data.name}>
                      <td>
                        <span
                          className={styles.colorSquare}
                          style={{ background: COLORS[index] }}></span>
                        {data.name}
                      </td>
                      <td className="ml-2">{data.value}</td>
                    </tr>
                  ))}
                </>
              )}
              <tr className="color-twilight-1000 font-weight-bold">
                <td>Total Applications</td>
                <td className="ml-2 mt-2">{isExpress ? quantity : totalVehicles}</td>
              </tr>
              <tr className="color-twilight-1000">
                <td>
                  <div className="mt-3">Total Down Payment</div>
                  <div>For Each Vehicle</div>
                </td>
                <td className="ml-2">{downPayment?.formattedValue ?? 0}.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid.Item>
    </Grid>
  );
}
