import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import useCocaStore from '../../../../store';
import { AEMSubRoute } from '../../../../utils/aem/aemRoute';
import SensitiveContent from '../../../common/SensitiveContent';

export default function BusinessInformationReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  const businessInfo = getValues('businessInfo');
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);
  const BUSINESS_ADDRESS = `${businessInfo?.city}, ${businessInfo?.state.label} ${businessInfo?.zip}`;
  const GUARAGE_ADDRESS =
    businessInfo.hasVehicleGarageAddress &&
    `${businessInfo?.vehicleGarageAddress?.city}, ${businessInfo?.vehicleGarageAddress?.state.label} ${businessInfo?.vehicleGarageAddress?.zip}`;

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_BUSINESS_INFORMATION_READONLY}`}>
      <div className="read-only-wrapper">
        <Grid className="gap-y-2">
          <Grid.Item
            s={12}
            m={6}
            l={3}
            xl={3}>
            <div className="font-weight-bold font-xsmall my-2">First Name</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.firstName')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}
            xl={3}>
            <div className="font-weight-bold font-xsmall my-2">Last Name</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.lastName')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Business Phone Number</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.phoneNumber')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Business Legal Name</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.businessName')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Enterprise Type</div>
            <div className="font-xsmall my-2">{getValues('businessInfo.enterpriseType.label')}</div>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Years in Business</div>
            <div className="font-xsmall my-2">{getValues('businessInfo.yearsInBusiness')}</div>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Business Address</div>
            <SensitiveContent className="font-xsmall mt-2">{getValues('businessInfo.streetAddress')}</SensitiveContent>
            <SensitiveContent className="font-xsmall mb-2">{BUSINESS_ADDRESS}</SensitiveContent>
          </Grid.Item>
          {isPilotDealer && (
            <Grid.Item
              s={12}
              m={6}
              l={3}>
              <div className="font-weight-bold font-xsmall my-2">Vehicle Garaging Address</div>
              <SensitiveContent className="font-xsmall mt-2">
                {businessInfo.hasVehicleGarageAddress ? businessInfo?.vehicleGarageAddress?.streetAddress : businessInfo?.streetAddress}
              </SensitiveContent>
              <SensitiveContent className="font-xsmall mb-2">
                {businessInfo.hasVehicleGarageAddress ? GUARAGE_ADDRESS : BUSINESS_ADDRESS}
              </SensitiveContent>
            </Grid.Item>
          )}

          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Tax ID</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.taxId')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Monthly Gross Profit</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('businessInfo.monthlyGrossProfit.formattedValue')}</SensitiveContent>
          </Grid.Item>
        </Grid>
      </div>
    </AEMSubRoute>
  );
}
