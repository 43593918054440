import { AEMSubRoute } from '../../../../utils/aem/aemRoute';
import EmploymentInfoReadOnly from '../EmploymentInformation/EmploymentInformationReadOnly';
import PersonalInfoReadOnly from '../PersonalInformation/PersonalInformationReadOnly';
import ResidenceInfoReadOnly from '../ResidenceInformation/ResidenceInformationReadOnly';

export default function GuarantorInformationReadOnly() {
  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_GUARANTOR_INFORMATION_READONLY}`}>
      <PersonalInfoReadOnly />
      <ResidenceInfoReadOnly />
      <EmploymentInfoReadOnly />
    </AEMSubRoute>
  );
}
