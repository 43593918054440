import { InLineAlert } from '@fcamna/react-library';
import { useState } from 'react';

export default function TeAlert() {
  const [isAlertVisible, setIsAlertVisible] = useState(true);
  return (
    isAlertVisible && (
      <div className="d-flex align-center te-alert my-4 gap-x-1">
        <InLineAlert
          titleProp={{
            title: 'This application will be transmitted electronically.',
            headingLevel: 'h1'
          }}
          body="By submitting this application, you agree to receive any required notices or disclosures related to this application electronically via this website or the email address/contact information you provide in the application."
          variant="information"
          onClickClose={() => setIsAlertVisible(false)}
        />
      </div>
    )
  );
}
