import { Button, Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { MSRP_LIMITS } from '../../../../constants/vehicle.constant';
import { useAppContext } from '../../../../context/appContext';
import useVehicleMSRPCalculator from '../../../../hooks/useVehicleMSRPCalculator';
import { FormStepNames } from '../../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import useCocaStore from '../../../../store';
import { SessionStorageUtils } from '../../../../utils/SessionStorageUtils';

interface Props {
  handleUpdate: () => void;
  handleCancel: () => void;
  step: FormStepNames;
}

export default function ReviewModifyButtons(props: Readonly<Props>) {
  const { handleUpdate, handleCancel, step } = props;
  const { trigger, setValue, getValues } = useFormContext<SubmitApplicationFormData>();
  const {
    businessSameAsContact,
    personalSameAsContact,
    guarantorSameAsBusiness,
    updateGuarantorSameAsBusiness,
    updateBusinessSameAsContact,
    updatePersonSameAsContact
  } = useAppContext();
  const { totalMSRP } = useVehicleMSRPCalculator();
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);

  const handleNextClick = async () => {
    const isValid = await trigger(step);
    if (isValid && handleUpdate && (await validateOtherFields())) {
      handleUpdate();
      if (step === 'applicantInformation') {
        businessSameAsContact && updateBusinessInformation();
        personalSameAsContact && updatePersonalInformation();
      }
      if (step === 'businessInfo') {
        guarantorSameAsBusiness && updateGuarantorInformation();
      }
      step === 'businessInfo' && updateBusinessSameAsContact();
      if (step === 'guarantorInfo') {
        updatePersonSameAsContact();
        updateGuarantorSameAsBusiness();
      }
      SessionStorageUtils.setParsedItem('formData', getValues());
    }
  };

  const validateOtherFields = async () => {
    const results = await Promise.all([isVehicleStepValid(), isClocStepValid(), isGuarantorStepValid()]);
    return results.every(Boolean);
  };

  const isGuarantorStepValid = async () => {
    return step === 'guarantorInfo' ? await trigger('hasGuarantor') : true;
  };

  const updateBusinessInformation = () => {
    setValue('businessInfo.firstName', getValues('applicantInformation.firstName'));
    setValue('businessInfo.lastName', getValues('applicantInformation.lastName'));
    setValue('businessInfo.phoneNumber', getValues('applicantInformation.phoneNumber'));
    updateBusinessSameAsContact();
  };

  const updatePersonalInformation = () => {
    setValue('guarantorInfo.personalInformation.firstName', getValues('applicantInformation.firstName'));
    setValue('guarantorInfo.personalInformation.lastName', getValues('applicantInformation.lastName'));
    setValue('guarantorInfo.personalInformation.phoneNumber', getValues('applicantInformation.phoneNumber'));
    setValue('guarantorInfo.personalInformation.email', getValues('applicantInformation.emailAddress'));
    updatePersonSameAsContact();
  };

  const updateGuarantorInformation = () => {
    setValue('guarantorInfo.residenceInformation.streetAddress', getValues('businessInfo.streetAddress'));
    setValue('guarantorInfo.residenceInformation.city', getValues('businessInfo.city'));
    setValue('guarantorInfo.residenceInformation.state', getValues('businessInfo.state'));
    setValue('guarantorInfo.residenceInformation.zip', getValues('businessInfo.zip'));
    updateGuarantorSameAsBusiness();
  };

  const isClocStepValid = async () => {
    if (step === 'vehicles') {
      return isPilotDealer ? await trigger('hasClocCredit') : true;
    } else {
      return true;
    }
  };

  const isVehicleStepValid = async () => {
    if (step === 'vehicles') {
      if (isPilotDealer) {
        return await validatePilotDealerVehicle();
      } else {
        return totalMSRP < MSRP_LIMITS.NON_PILOT_DEALER_MAX_LIMIT;
      }
    } else {
      return true;
    }
  };

  const validatePilotDealerVehicle = async () => {
    const isExpress = getValues('isExpress');
    return !isExpress ? totalMSRP < MSRP_LIMITS.PILOT_DEALER_MAX_LIMIT : true;
  };

  return (
    <div>
      <Grid className="my-10 gap-2">
        <Button
          onClick={handleCancel}
          variant="outline">
          Cancel
        </Button>
        <Button onClick={handleNextClick}>Update</Button>
      </Grid>
    </div>
  );
}
