import { Grid } from '@fcamna/react-library';

import { GuarantorResidenceInfoFormData } from '../../../../models/formData/GuarantorResidenceInfoFormData';
import SensitiveContent from '../../../common/SensitiveContent';

interface Props {
  residenceInformation: GuarantorResidenceInfoFormData;
}

export default function ResidenceInformationReview(props: Readonly<Props>) {
  const { residenceInformation } = props;
  return (
    <>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Street Address</div>
        <SensitiveContent className="font-xsmall mt-2">{residenceInformation.streetAddress}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">City</div>
        <SensitiveContent className="font-xsmall mt-2">{residenceInformation.city}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">State</div>
        <SensitiveContent className="font-xsmall mt-2">{residenceInformation?.state?.value}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Zip Code</div>
        <SensitiveContent className="font-xsmall mt-2">{residenceInformation.zip}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Residence Type</div>
        <SensitiveContent className="font-xsmall my-2">{residenceInformation.residenceType.label}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Rent/Mortgage</div>
        <SensitiveContent className="font-xsmall my-2">{residenceInformation.rentMortgage.formattedValue}</SensitiveContent>
      </Grid.Item>
    </>
  );
}
