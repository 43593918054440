import { useEffect } from 'react';

const GlobalLinks = () => {
  useEffect(() => {
    const loadLink = (url: string) => {
      return new Promise((resolve, reject) => {
        const link: any = document.createElement('link');
        link.href = url;
        link.rel = 'preconnect';
        link.onload = resolve;
        link.onerror = reject;
        document.head.appendChild(link);
      });
    };

    const loadScripts = async () => {
      try {
        await loadLink(process.env.GOOGLE_FONTS_API as string);
        await loadLink(process.env.CXC_ASSETS_API as string);
        await loadLink(process.env.AEM_DEV_AUTHOR as string);
      } catch (error) {
        console.error('Error loading link:', error);
      }
    };

    loadScripts();
  }, []);

  return null;
};

export default GlobalLinks;
