import { useEffect, useState } from 'react';

const BREAKPOINTS = Object.freeze({
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 1024,
  XL: 1200
});

const defaultBreakPoints = {
  xs: false,
  s: false,
  m: false,
  l: false,
  xl: false
};

const useBreakpoint = () => {
  const [breakPoint, setBreakPoint] = useState(defaultBreakPoints);
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    if (BREAKPOINTS.XS < windowSize.width && windowSize.width < BREAKPOINTS.SM) {
      setBreakPoint({ ...defaultBreakPoints, xs: true });
    }
    if (BREAKPOINTS.SM < windowSize.width && windowSize.width < BREAKPOINTS.MD) {
      setBreakPoint({ ...defaultBreakPoints, s: true });
    }
    if (BREAKPOINTS.MD < windowSize.width && windowSize.width < BREAKPOINTS.LG) {
      setBreakPoint({ ...defaultBreakPoints, m: true });
    }
    if (BREAKPOINTS.LG < windowSize.width && windowSize.width < BREAKPOINTS.XL) {
      setBreakPoint({ ...defaultBreakPoints, l: true });
    }
    if (windowSize.width >= BREAKPOINTS.XL) {
      setBreakPoint({ ...defaultBreakPoints, xl: true });
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  return breakPoint;
};

export default useBreakpoint;
