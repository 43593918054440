interface SensitiveContentProps {
  children: React.ReactNode;
  className?: string;
}

export default function SensitiveContent(props: Readonly<SensitiveContentProps>) {
  return (
    <div
      {...props}
      data-cs-mask="">
      {props.children}
    </div>
  );
}
