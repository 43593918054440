import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../../utils/aem/aemRoute';
import SensitiveContent from '../../../common/SensitiveContent';

export default function ContactInformationReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_CONTACT_INFORMATION_READONLY}`}>
      <div className="read-only-wrapper">
        <Grid className="gap-y-2">
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">First Name</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('applicantInformation.firstName')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Last Name</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('applicantInformation.lastName')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Mobile Number</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('applicantInformation.phoneNumber')}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            s={12}
            m={6}
            l={3}>
            <div className="font-weight-bold font-xsmall my-2">Email Address</div>
            <SensitiveContent className="font-xsmall my-2">{getValues('applicantInformation.emailAddress')}</SensitiveContent>
          </Grid.Item>
        </Grid>
      </div>
    </AEMSubRoute>
  );
}
