import axios from 'axios';

import { DisplayModel } from '../models/DisplayModel';
import { Vehicle } from '../models/Vehicle';
import { getWithApiKey } from './AxiosHelper';

const SERVICES_URL = process.env.OCA_SERVICES_URL;

export const fetchVehicleYears = async (): Promise<number[]> => {
  const response = await getWithApiKey(`${SERVICES_URL}/api/v1/vehicle/years`);
  return response.status === 200 ? response.data.years : [];
};

export const fetchVehicleModels = async (year: number): Promise<DisplayModel[]> => {
  const response = await getWithApiKey(`${SERVICES_URL}/api/v1/vehicle/years/${year}/models`);
  return response.status === 200 ? response.data.models : [];
};

export const fetchVehicleTrims = async (year: number, model: string): Promise<Record<string, Vehicle>> => {
  const response = await getWithApiKey(`${SERVICES_URL}/api/v1/vehicle/years/${year}/models/${model}/trims`);
  return response.status === 200 ? response.data.trims : {};
};

export const validateVehiclePhoto = async (url: string): Promise<boolean> => {
  try {
    await axios.get(encodeURI(url));
    return true;
  } catch (error) {
    return false;
  }
};
