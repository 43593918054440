import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAppContext } from '../context/appContext';
import { SubmitApplicationFormData } from '../models/SubmitApplication';
import { fetchDealerById } from '../services/DealerApi';

const PACODE_PARAM = 'paCode';

const useDealer = () => {
  const { dealerId } = useParams();
  const { setIsProvidedDealer } = useAppContext();
  const { setValue } = useFormContext<SubmitApplicationFormData>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const paCode = searchParams.get(PACODE_PARAM);
    if (paCode) {
      window.location.href = process.env.FORD_PRO_URL as string;
    }
  }, [searchParams]);

  useEffect(() => {
    if (typeof dealerId === 'string') {
      fetchDealerById(dealerId)
        .then((dealer) => {
          setIsProvidedDealer(true);
          setValue('dealer.data', dealer);
        })
        .catch(() => {
          setIsProvidedDealer(false);
          setValue('dealer.data', undefined);
          window.location.href = process.env.FORD_PRO_URL as string;
        });
    }
  }, [dealerId, setIsProvidedDealer, setValue]);
};

export default useDealer;
