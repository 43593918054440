import { Card } from '@fcamna/react-library';

import { Page } from '../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import useCocaStore from '../../../store';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';
import DetailedVehicleReview from './NonPilotDealerVehicleReview';
import PilotDealerVehicleReview from './PilotDealerVehicleReview/PilotDealerVehicleReview';

interface Props {
  vehicleInformation: SubmitApplicationFormData['vehicles'];
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

function VehicleReview(props: Readonly<Props>) {
  const { vehicleInformation, handleModify, updateStep, setUpdatedStep } = props;
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title={isPilotDealer ? 'Credit Applications' : 'Your Vehicle(s)'}
        handleModify={() => {
          handleModify(Page.VEHICLE);
          setUpdatedStep(Page.VEHICLE);
        }}
        currentUpdatedStep={updateStep === Page.VEHICLE}
      />
      {isPilotDealer ? <PilotDealerVehicleReview /> : <DetailedVehicleReview vehicleInformation={vehicleInformation} />}
      <ReviewFooter
        handleModify={() => {
          handleModify(Page.VEHICLE);
          setUpdatedStep(Page.VEHICLE);
        }}
      />
    </Card>
  );
}

export default VehicleReview;
