import { Grid } from '@fcamna/react-library';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { EmploymentStatus, EmploymentType, IncomeInterval } from '../../../../models/DataTypes';
import { LabelValue, SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import AEMInput from '../../../common/AEM/Input/AEMInput';
import ButtonGroup, { ButtonNameValue } from '../../../common/Buttons/ButtonGroup/ButtonGroup';
import CurrencyInput from '../../../common/CurrencyInput';
import SelectInput from '../../../common/SelectInput';

export default function EmploymentInformation() {
  const { clearErrors, trigger, setValue } = useFormContext<SubmitApplicationFormData>();

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const employmentTypeOptions: LabelValue<EmploymentType>[] = [
    { label: 'Auto Worker', value: 'AUTO_WORKER' },
    { label: 'Clerical', value: 'CLERICAL' },
    { label: 'Craftsman', value: 'CRAFTSMAN' },
    { label: 'Executive/Managerial', value: 'EXECUTIVE_MANAGERIAL' },
    { label: 'Farmer', value: 'FARMER' },
    { label: 'Fisherman', value: 'FISHERMAN' },
    { label: 'Government', value: 'GOVERNMENT' },
    { label: 'Homemaker', value: 'HOMEMAKER' },
    { label: 'Other', value: 'OTHER' },
    { label: 'Professional', value: 'PROFESSIONAL' },
    { label: 'Sales/Advertising', value: 'SALES_ADVERTISING' },
    { label: 'Semi-Skilled Labor', value: 'SEMI_SKILLED_LABOR' },
    { label: 'Skilled Labor', value: 'SKILLED_LABOR' }
  ];

  const employmentStatusOptions: LabelValue<EmploymentStatus>[] = [
    { label: 'Military', value: 'MILITARY' },
    { label: 'Contract', value: 'CONTRACT' },
    { label: 'Full Time', value: 'FULL_TIME' },
    { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
    { label: 'Part Time', value: 'PART_TIME' },
    { label: 'Retired', value: 'RETIRED' },
    { label: 'Seasonal', value: 'SEASONAL' },
    { label: 'Self-Employed', value: 'SELF_EMPLOYED' },
    { label: 'Temporary', value: 'TEMPORARY' }
  ];

  const incomeIntervalOptions: ButtonNameValue<IncomeInterval>[] = [
    {
      aemName: 'annual',
      label: 'Annual',
      value: 'ANNUAL'
    },
    {
      aemName: 'monthly',
      label: 'Monthly',
      value: 'MONTHLY'
    },
    {
      aemName: 'bi-weekly',
      label: 'Bi-weekly',
      value: 'BIWEEKLY'
    }
  ];

  return (
    <div className="mt-4">
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div>
            <SelectInput
              name="guarantorInfo.employmentInformation.employmentStatus"
              label="Employment Status"
              placeholder="Select..."
              options={employmentStatusOptions}
              onChange={() => trigger('guarantorInfo.employmentInformation.employmentStatus')}
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div>
            <SelectInput
              name="guarantorInfo.employmentInformation.employmentType"
              label="Employment Type"
              placeholder="Select..."
              options={employmentTypeOptions}
              onChange={() => trigger('guarantorInfo.employmentInformation.employmentType')}
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.employmentInformation.occupation"
              aemName="primary-occupation"
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name="guarantorInfo.employmentInformation.employerName"
              aemName="primary-employeer"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <CurrencyInput
              type="text"
              aemName="total-gross-income"
              name={'guarantorInfo.employmentInformation.grossIncome'}
              infoProp={{
                infoText:
                  'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
                infoType: 'popuptip',
                popupTipHeader: 'Total Gross Income'
              }}
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={3}
          l={3}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'guarantorInfo.employmentInformation.monthsOnJob'}
              aemName="months-in-job"
              maxLength={3}
              mask="___"
            />
          </div>
        </Grid.Item>
      </Grid>
      <div className="cf-field">
        <ButtonGroup
          name="guarantorInfo.employmentInformation.incomeInterval"
          aemName="income-interval"
          buttonProps={incomeIntervalOptions}
          onClick={(value) => {
            setValue('guarantorInfo.employmentInformation.incomeInterval', { label: value.label, value: value.value });
            trigger('guarantorInfo.employmentInformation.incomeInterval');
          }}
        />
      </div>
    </div>
  );
}
