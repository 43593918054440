import { Model, ModelClient } from '@adobe/aem-spa-page-model-manager';

export class CustomModelClient extends ModelClient {
  private user: string | undefined;
  private password: string | undefined;
  constructor(apiURL: string, user?: string | undefined, password?: string | undefined) {
    super(apiURL);
    this.user = user;
    this.password = password;
  }

  fetch<M extends Model>(modelPath: string): Promise<M> {
    if (!modelPath) {
      const err = `Fetching model rejected for path: ${modelPath}`;

      return Promise.reject(new Error(err));
    }

    // Either the API host has been provided or we make an absolute request relative to the current host
    const apihostPrefix = this.apiHost ?? '';
    const url = `${apihostPrefix}${modelPath}`;

    // Assure that the default credentials value ('same-origin') is set for browsers which do not set it
    // or which are setting the old default value ('omit')
    let headers = {};
    if (apihostPrefix.indexOf('localhost') >= 0 && this.user && this.password) {
      headers = { Authorization: this.getToken() };
    }

    return fetch(url, { headers })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json() as Promise<M>;
        }

        throw new Error(response.statusText);
      })
      .catch((error) => {
        return Promise.reject(new Error(error));
      });
  }

  getToken() {
    const token = `${this.user}:${this.password}`;
    const hash = window.btoa(token);
    return `Basic ${hash}`;
  }
}
