import { Grid } from '@fcamna/react-library';

import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import AEMInput from '../../common/AEM/Input/AEMInput';

export default function ContactInformation() {
  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:contact information`);

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_CONTACT_INFORMATION}`}>
      <div>
        <Grid style={{ rowGap: '24px' }}>
          <Grid.Item
            xl={3}
            l={3}
            m={6}
            s={12}>
            <div>
              <AEMInput
                name={'applicantInformation.firstName'}
                aemName="application-information-first-name"
              />
            </div>
          </Grid.Item>
          <Grid.Item
            xl={3}
            l={3}
            m={6}
            s={12}>
            <div>
              <AEMInput
                name={'applicantInformation.middleName'}
                aemName="application-information-middle-name"
              />
            </div>
          </Grid.Item>
          <Grid.Item
            xl={5}
            l={5}
            m={10}
            s={12}>
            <div>
              <AEMInput
                name={'applicantInformation.lastName'}
                aemName="application-information-last-name"
              />
            </div>
          </Grid.Item>
        </Grid>
        <Grid>
          <Grid.Item
            xl={3}
            l={3}
            m={6}
            s={12}>
            <div className="cf-field">
              <AEMInput
                name={'applicantInformation.phoneNumber'}
                aemName="application-information-phone-number"
                mask="(___) ___-____"
              />
            </div>
          </Grid.Item>
        </Grid>
        <Grid>
          <Grid.Item
            xl={6}
            l={6}
            m={10}
            s={12}>
            <div className="cf-field">
              <AEMInput
                name={'applicantInformation.emailAddress'}
                aemName="application-information-email-address"
              />
            </div>
          </Grid.Item>
        </Grid>
      </div>
    </AEMSubRoute>
  );
}
