import { fordProIcons } from '@fcamna/shared-icons';

const { WarningFilled } = fordProIcons;

function ErrorFallback() {
  return (
    <div>
      <div>
        <div />
        <div className="field-error m-0">
          <WarningFilled
            width={16}
            height={16}
          />
          <b>Oops, something went wrong.</b>
        </div>
      </div>
      <p className="font-xsmall color-neutral-600">Please try again later</p>
    </div>
  );
}

export default ErrorFallback;
