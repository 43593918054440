import { Grid } from '@fcamna/react-library';

import { GuarantorEmploymentInfoFormData } from '../../../../models/formData/GuarantorEmploymentInfoFormData';
import SensitiveContent from '../../../common/SensitiveContent';

interface Props {
  employmentInformation: GuarantorEmploymentInfoFormData;
}

export default function EmploymentInformationReview(props: Readonly<Props>) {
  const { employmentInformation } = props;
  return (
    <>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Employment Status</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.employmentStatus.label}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Employment Type</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.employmentType.label}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Primary Occupation</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.occupation}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Primary Employer</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.employerName}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Total Gross Income</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.grossIncome.formattedValue}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Income Interval</div>
        <SensitiveContent className="font-xsmall my-2">{employmentInformation.incomeInterval.label}</SensitiveContent>
      </Grid.Item>
    </>
  );
}
