import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { IInfoProp } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

const { WarningFilled } = fordProIcons;

interface AEMInputProps extends TextInputProps {
  name: string;
  aemName: string;
  type?: string;
  optionalText?: string;
  mask?: string;
}

interface InputProps extends AEMInputProps {
  field: any;
  fieldState: any;
  helperText?: any;
  infoProp?: IInfoProp;
}

export default function CurrencyInput(props: Readonly<AEMInputProps>) {
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <NumericFormat
          prefix={'$'}
          customInput={Input}
          thousandSeparator={true}
          decimalScale={0}
          maxLength={8}
          allowNegative={false}
          onValueChange={(values) => {
            field.onChange(values);
          }}
          onBlur={() => {
            field.onBlur();
          }}
          value={field.value?.formattedValue}
          name={props.name}
          field={field}
          fieldState={fieldState}
          infoProp={props.infoProp}
          aemName={props.aemName}
          placeholder={props.placeholder}
          className={props.className}
          helperText={
            (fieldState.error as any)?.floatValue?.message && (
              <div className="field-error m-0">
                <WarningFilled
                  width={16}
                  height={16}
                />
                {(fieldState.error as any)?.floatValue?.message}
              </div>
            )
          }
          isInvalid={fieldState.error != undefined}
        />
      )}
    />
  );
}

function Input({ onChange, onBlur, aemName, ...props }: Readonly<InputProps>) {
  return (
    <EditableTextInput
      {...props}
      name={aemName}
      onChange={onChange}
      onBlur={onBlur}
      infoProp={props.infoProp}
      className="input-wrapper"
    />
  );
}
