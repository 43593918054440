import { Grid } from '@fcamna/react-library';

import { GuarantorPersonalInfoFormData } from '../../../../models/formData/GuarantorPersonalInfoFormData';
import SensitiveContent from '../../../common/SensitiveContent';

interface Props {
  personalInformation: GuarantorPersonalInfoFormData;
}

export default function PersonalInformationReview(props: Readonly<Props>) {
  const { personalInformation } = props;
  const ssn = personalInformation.ssn.slice(-4);

  return (
    <>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">First Name</div>
        <SensitiveContent className="font-xsmall my-2">{personalInformation.firstName}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Last Name</div>
        <SensitiveContent className="font-xsmall my-2">{personalInformation.lastName}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Phone Number</div>
        <SensitiveContent className="font-xsmall my-2">{personalInformation.phoneNumber}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Applicant Email Address</div>
        <SensitiveContent className="font-xsmall my-2">{personalInformation.email}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Date of Birth</div>
        <SensitiveContent className="font-xsmall my-2">{personalInformation.dob}</SensitiveContent>
      </Grid.Item>
      <Grid.Item
        xl={4}
        l={4}
        m={6}
        s={12}>
        <div className="font-weight-bold font-xsmall my-2">Social Security Number</div>
        <SensitiveContent className="font-xsmall my-2">*****{ssn}</SensitiveContent>
      </Grid.Item>
    </>
  );
}
