import isPropValid from '@emotion/is-prop-valid';
import { Mode, ThemeName, Themes } from '@fcamna/shared-component-foundations';
import { ReactNode } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

export interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  return (
    <StyleSheetManager
      shouldForwardProp={shouldForwardProp}
      disableCSSOMInjection>
      <ThemeProvider theme={Themes[ThemeName.FORD_PRO][Mode.LIGHT_MODE]}>
        <div className="rootContainer">
          <div className="layout">{children}</div>
        </div>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

function shouldForwardProp(propName: any, target: any) {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }

  return true;
}

export default Layout;
