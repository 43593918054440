import ScriptElement from '../ScriptElement';
import styles from './Header.module.scss';

export default function Header() {
  return (
    <div className={styles.headerWrapper}>
      <ScriptElement type={'fpg-header'} />
    </div>
  );
}
