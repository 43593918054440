import { Payload, track, trackFPClick, VariableDigitalData } from '@fcsmc/commercial-analytics';

import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../utils/constants';
import { getBaseDomain, getDomain, getFullUrl } from '../utils/jsUtils';

export const trackAnalayticsOnLoad = (pageName: string, additionalData?: any): void => {
  const variableDigitalData: VariableDigitalData = getDefaultDigitalData(pageName);

  if (additionalData != null) {
    variableDigitalData.page.tool = additionalData.page?.tool;
    variableDigitalData.page.toolDescriptor = additionalData.page?.toolDescriptor;
  }
  if (variableDigitalData.user != null && additionalData?.user != null) {
    const user = variableDigitalData.user as any;
    user.cksVisitId = additionalData?.user.cksVisitId;
    user.leadType = additionalData?.user.leadType;
  }
  track('globalLoad', variableDigitalData);
};

export const trackAnalayticsOnClick = (pageName: string, onclick: string): void => {
  const payload: Payload = getDefaultOnClickPayload(pageName);
  payload.onclick = onclick?.replace('fpro:', '');
  trackFPClick(payload, 'globalInteraction');
};

export const trackAnalayticsOnExit = (pageName: string, externalLink: string): void => {
  const variableDigitalData: VariableDigitalData = getDefaultDigitalData(pageName);
  variableDigitalData.page.pageName = pageName;
  variableDigitalData.page.tool = 'event:brand referral';
  variableDigitalData.page.toolDescriptor = `referral:${getFullUrl(externalLink)}`;

  if (variableDigitalData.event != null) {
    variableDigitalData.event.action = 'brand referral, too';
  }
  if (variableDigitalData.onclick != null) {
    variableDigitalData.onclick.onclickLinkName = `exit:${getBaseDomain(externalLink)}`;
    variableDigitalData.onclick.onclick = `referral:${getDomain(externalLink)}`;
    variableDigitalData.onclick.referredTo = getDomain(externalLink);
  }
  track('referral-exit', variableDigitalData);
};

export const trackAnalayticsOnClickEmail = (pageName: string, linkName: string, onclick: string): void => {
  const variableDigitalData: VariableDigitalData = getDefaultDigitalData(pageName);
  variableDigitalData.page.pageName = pageName;
  variableDigitalData.page.tool = 'event:lead submitted';
  variableDigitalData.page.toolDescriptor = 'lead submitted:click to call';
  if (variableDigitalData.event != null) {
    variableDigitalData.event.action = 'lead submitted, updates opt-In, tools';
  }
  if (variableDigitalData.onclick != null) {
    variableDigitalData.onclick.onclickLinkName = linkName;
    variableDigitalData.onclick.onclick = onclick?.replace('fpro:', '');
  }
  if (variableDigitalData.user != null) {
    const user = variableDigitalData.user as any;
    user.leadType = 'click-to';
    user.leadOptIn = 'click to call';
  }
  track('call-email', variableDigitalData);
};

export const trackAnalayticsOnClickDownload = (pageName: string, clickLinkName: string, fileName: string): void => {
  const variableDigitalData: VariableDigitalData = getDefaultDigitalData(pageName);
  if (variableDigitalData.onclick != null) {
    variableDigitalData.onclick.onclickLinkName = clickLinkName;
    variableDigitalData.onclick.onclick = `${clickLinkName}:${fileName}`.replace('fpro:', '');
  }
  track('global-download', variableDigitalData);
};

const getDefaultDigitalData = (pageName: string): VariableDigitalData => {
  return {
    page: {
      hierarchy: FORD_PRO_ADOBE_PAGE_NAME_PREFIX,
      pageName: pageName,
      siteSection: 'financing',
      pageNameNoVehicle: pageName,
      client: 'fpro-us',
      site: 'pro.ford.com'
    },
    onclick: {
      onclickLinkName: ''
    },
    event: {
      action: ''
    },
    user: {}
  };
};

const getDefaultOnClickPayload = (pageName: string): Payload => {
  return {
    onclickLinkName: pageName,
    tool: '',
    toolDescriptor: '',
    financeNumberOfVehicles: '',
    financeMasterCustomerNumber: 0
  };
};
