import { Checkbox, CheckboxStateValues, Grid } from '@fcamna/react-library';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppContext } from '../../../../context/appContext';
import { ResidenceType } from '../../../../models/DataTypes';
import { LabelValue, SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { stateOptions } from '../../../../utils/StateData';
import AEMInput from '../../../common/AEM/Input/AEMInput';
import CurrencyInput from '../../../common/CurrencyInput';
import SelectInput from '../../../common/SelectInput';

export default function ResidenceInformation() {
  const { getValues, clearErrors, trigger, setValue } = useFormContext<SubmitApplicationFormData>();
  const { guarantorSameAsBusiness, setGuarantorSameAsBusiness } = useAppContext();

  const onEnable = () => {
    setValue('guarantorInfo.residenceInformation.streetAddress', getValues('businessInfo.streetAddress'));
    setValue('guarantorInfo.residenceInformation.city', getValues('businessInfo.city'));
    setValue('guarantorInfo.residenceInformation.state', getValues('businessInfo.state'));
    setValue('guarantorInfo.residenceInformation.zip', getValues('businessInfo.zip'));
    setGuarantorSameAsBusiness(CheckboxStateValues.CHECKED);
  };

  const onDisable = () => {
    setValue('guarantorInfo.residenceInformation.streetAddress', '');
    setValue('guarantorInfo.residenceInformation.city', '');
    setValue('guarantorInfo.residenceInformation.state', null);
    setValue('guarantorInfo.residenceInformation.zip', '');
    setGuarantorSameAsBusiness(CheckboxStateValues.UNCHECKED);
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  const residenceOptions: LabelValue<ResidenceType>[] = [
    { label: 'Owns Home', value: 'OWNS_HOME' },
    { label: 'Buying Home', value: 'BUYING_HOME' },
    { label: 'Living with Relatives', value: 'LIVING_WITH_RELATIVES' },
    { label: 'Renting or Leasing', value: 'RENTING_LEASING' },
    { label: 'Owns or Buying Mobile Home', value: 'OWNS_OR_BUYING_MOBILE_HOME' }
  ];

  return (
    <div>
      <div className="cf-field mb-4">
        <Checkbox
          label="Same as Business information"
          checkboxValue={guarantorSameAsBusiness}
          onChange={(_e, value) => {
            if (value === CheckboxStateValues.CHECKED) {
              onEnable();
              clearErrors();
            } else {
              onDisable();
            }
            setGuarantorSameAsBusiness(value);
          }}
        />
      </div>
      <Grid className="gap-y-2">
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <AEMInput
            type="text"
            name={'guarantorInfo.residenceInformation.streetAddress'}
            aemName="guarantor-street-address"
          />
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'guarantorInfo.residenceInformation.city'}
              aemName="guarantor-city"
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={2}
          l={2}
          m={6}
          s={5}>
          <div className="cf-field">
            <SelectInput
              name={'guarantorInfo.residenceInformation.state'}
              label={'State'}
              placeholder="Select..."
              options={stateOptions}
              onChange={() => {
                trigger('guarantorInfo.residenceInformation.state');
              }}
              isDefaultSelect={true}
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={2}
          l={3}
          m={6}
          s={7}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'guarantorInfo.residenceInformation.zip'}
              aemName="guarantor-zip-code"
              mask="_____"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={4}
          m={6}
          s={12}>
          <div className="cf-field">
            <SelectInput
              name={'guarantorInfo.residenceInformation.residenceType'}
              label={'Residence Type'}
              placeholder="Select..."
              options={residenceOptions}
              onChange={() => trigger('guarantorInfo.residenceInformation.residenceType')}
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={5}
          m={6}
          s={12}>
          <div className="cf-field">
            <CurrencyInput
              type="text"
              name={'guarantorInfo.residenceInformation.rentMortgage'}
              aemName="rent-mortage"
            />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
}
