import { Accordion, AccordionItem, AccordionPanel, Button, Grid, InLineAlert, Radio, RadioList, Spinner } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import React, { useEffect, useState } from 'react';

import { ConfirmationResponse } from '../../../context/appContext';
import { postFeedback } from '../../../services/FeedbackApi';
import { trackAnalayticsOnClick } from '../../../services/FordProAnalaytics';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import styles from './Feedback.module.scss';

const { Warning } = fordProIcons;

enum FeedbackState {
  OPEN,
  SUBMITTED,
  CLOSED
}

interface Props {
  confirmation: ConfirmationResponse | undefined;
  hasGuarantor: boolean;
}

function Feedback({ confirmation }: Readonly<Props>) {
  const [rating, setRating] = React.useState<number>();
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState<FeedbackState>(FeedbackState.OPEN);
  const feedBackValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [feedbackError, setFeedbackError] = useState<string | null>(null);
  const [canContact, setCanContact] = React.useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      if (feedbackSubmitted === FeedbackState.SUBMITTED) {
        setFeedbackSubmitted(FeedbackState.CLOSED);
      }
    }, 3000);
  }, [feedbackSubmitted]);

  useEffect(() => {
    if (isOpen) {
      const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete:content`;
      trackAnalayticsOnClick(`${tag} action`, `${tag}:accordion expand:feedback`);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    const requestBody = { appId: confirmation?.vehicleData[0].applicationId, rating: rating, canContact: canContact };
    setFeedbackError(null);
    setIsLoading(true);
    try {
      await postFeedback(requestBody);
      setFeedbackSubmitted(FeedbackState.SUBMITTED);
    } catch {
      setFeedbackError('Sorry, unfortunately our service is unavailable. Please retry at a later time.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-6 mb-20">
      {feedbackSubmitted === FeedbackState.OPEN && (
        <Accordion className={styles.feedbackMain}>
          <AccordionItem
            id={'1'}
            title="Submit Feedback"
            openAccordion={isOpen}
            setOpenAccordion={(_event, id, value) => setIsOpen(value)}
            boxVariation={true}>
            <AccordionPanel
              className={styles.feedbackSegmentMain}
              content={
                <div className={styles.feedbackSegmentContainer}>
                  <div className={styles.feedbackSegmentContent}>
                    <div className={styles.feedbackRatingContainer}>
                      <Grid
                        gutterWidth={8}
                        className={styles.feedbackGrid}>
                        <Grid.Item
                          s={12}
                          m={6}
                          l={6}
                          xl={6}>
                          <div className="d-flex flex-column align-start">
                            <div className="d-flex gap-2">
                              {feedBackValues.slice(0, 5).map((value) => {
                                return (
                                  <Button
                                    key={value}
                                    value={value}
                                    size="small"
                                    variant="outline"
                                    color="primary"
                                    as="button"
                                    onClick={() => setRating(value)}
                                    className={rating === value ? styles.active : ''}>
                                    {value}
                                  </Button>
                                );
                              })}
                            </div>
                            <span className="color-neutral-600 font-xsmall pt-3">Not satisfied</span>
                          </div>
                        </Grid.Item>
                        <Grid.Item
                          s={12}
                          m={6}
                          l={6}
                          xl={6}>
                          <div className={`${styles.ratingCol2} d-flex flex-column`}>
                            <div className="d-flex gap-2">
                              {feedBackValues.slice(5, 10).map((value) => {
                                return (
                                  <Button
                                    key={value}
                                    value={value}
                                    size="small"
                                    variant="outline"
                                    color="primary"
                                    as="button"
                                    onClick={() => setRating(value)}
                                    className={rating === value ? styles.active : ''}>
                                    {value}
                                  </Button>
                                );
                              })}
                            </div>
                            <span className="color-neutral-600 font-xsmall pt-3">Satisfied</span>
                          </div>
                        </Grid.Item>
                      </Grid>
                    </div>
                  </div>
                  <div className="my-3">
                    <RadioList
                      label="May we contact you regarding your experience?"
                      onChange={(option) => setCanContact(option === 1)}
                      className={styles.radioButtonWrapper}>
                      <Radio
                        key={1}
                        label="Yes"
                        value={1}
                      />
                      <Radio
                        key={2}
                        label="No"
                        value={2}
                      />
                    </RadioList>
                  </div>
                  <div className={styles.submitWrapper}>
                    <Button
                      onClick={() => handleSubmit()}
                      size="medium"
                      variant="outline"
                      className="mt-3"
                      isDisabled={rating == null || canContact == null}>
                      Submit
                    </Button>
                    {isLoading && <Spinner className="mt-3" />}
                  </div>

                  {feedbackError && (
                    <div className="field-error">
                      <Warning
                        width={16}
                        height={16}
                      />
                      {feedbackError}
                    </div>
                  )}
                </div>
              }
            />
          </AccordionItem>
        </Accordion>
      )}
      {feedbackSubmitted === FeedbackState.SUBMITTED && (
        <div className="fit-content">
          <Grid>
            <InLineAlert
              body="Thank you for your feedback!"
              variant="success"
              onClickClose={() => setFeedbackSubmitted(FeedbackState.CLOSED)}
            />
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Feedback;
