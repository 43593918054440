import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useMask } from '@react-input/mask';
import { Controller } from 'react-hook-form';

const { WarningFilled } = fordProIcons;

interface AEMInputProps extends TextInputProps {
  name: string;
  aemName: string;
  type?: string;
  optionalText?: string;
  mask?: string;
}

interface InputProps extends AEMInputProps {
  field: any;
  fieldState: any;
  helperText?: any;
}

export default function AEMInput(props: Readonly<AEMInputProps>) {
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <Input
          field={field}
          fieldState={fieldState}
          showErrorIcon={true}
          helperText={
            fieldState.error?.message && (
              <div className="field-error m-0">
                {' '}
                <WarningFilled
                  width={16}
                  height={16}
                />
                {fieldState.error?.message}
              </div>
            )
          }
          {...props}
        />
      )}></Controller>
  );
}

function Input(props: Readonly<InputProps>) {
  const inputRef = useMask({ mask: props?.mask, replacement: { _: /\d/ } });

  return (
    <EditableTextInput
      {...props}
      type={props.type}
      name={props.aemName}
      {...(props.mask && { inputRef: inputRef })}
      isInvalid={props.fieldState.error != undefined}
      onChange={(e) => {
        props.field.onChange(e);
      }}
      showErrorIcon={props.showErrorIcon}
      value={props.field.value || ''}
      onBlur={() => props.field.onBlur()}
      className="input-wrapper"
    />
  );
}
