import * as yup from 'yup';

import {
  datePattern,
  emailAddressPattern,
  namePattern,
  noFinalHyphenApostrophePattern,
  noInitialHyphenApostrophePattern,
  phoneNumberPattern,
  zipCodePattern
} from './regexPatterns';

export enum charactersLimit {
  TWENTY_FOUR = 24,
  EMAIL_MAX_CHARACTER_LIMIT = 60
}

export const firstNameSchema = () => {
  return yup
    .string()
    .trim()
    .required('First Name is required')
    .max(charactersLimit.TWENTY_FOUR, "You've reached the character limit")
    .matches(noInitialHyphenApostrophePattern, 'First Name cannot start with special character')
    .matches(noFinalHyphenApostrophePattern, 'First Name cannot end with special character')
    .matches(namePattern, 'Special characters and numbers are not allowed');
};

export const lastNameSchema = () => {
  return yup
    .string()
    .trim()
    .max(charactersLimit.TWENTY_FOUR, "You've reached the character limit")
    .required('Last Name is required')
    .matches(noInitialHyphenApostrophePattern, 'Last Name cannot start with special character')
    .matches(noFinalHyphenApostrophePattern, 'Last Name cannot end with special character')
    .matches(namePattern, 'Special characters and numbers are not allowed');
};

export const middleNameSchema = () => {
  return yup.string().trim().max(charactersLimit.TWENTY_FOUR, "You've reached the character limit");
};

export const phoneNumberSchema = (inputLabel = 'Phone Number') => {
  return yup
    .string()
    .required(inputLabel + ' is required')
    .matches(phoneNumberPattern, 'Please enter a valid ' + inputLabel);
};

export const emailAddressSchema = () => {
  return yup
    .string()
    .trim()
    .max(charactersLimit.EMAIL_MAX_CHARACTER_LIMIT, "You've reached the character limit")
    .required('Email Address is required')
    .matches(emailAddressPattern, 'Please enter a valid email address');
};

export const zipCodeSchema = () => {
  return yup.string().required('Zip Code is required').matches(zipCodePattern, 'Zip Code must be 5 digits');
};

export const taxIdSchema = () => {
  return yup.string().required('Tax ID is required').min(10, 'Tax ID must be 9 digits');
};

export const dobSchema = () => {
  return yup
    .string()
    .required('Date of Birth is required')
    .test('validate-date', 'Please enter in a valid date', function (value) {
      const m = RegExp(datePattern).exec(value);
      if (!m) return false;
      const d = new Date(value);
      // Now let's ensure that the date is not out of index
      if (d.getMonth() + 1 == parseInt(m[1], 10) && d.getDate() == parseInt(m[2], 10)) {
        return true;
      }
      return false;
    })
    .test('not-past-date', 'Date of Birth cannot be in the future', function (value) {
      const d = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (d <= today) {
        return true;
      }
      return false;
    });
};
