import { Dealer } from '../models/Dealer';
import { getWithApiKey, postWithApiKey } from './AxiosHelper';

const SERVICES_URL = process.env.OCA_SERVICES_URL;

export const fetchDealersByPostalCode = async (postalCode: string | undefined): Promise<Dealer[]> => {
  const response = await getWithApiKey(`${SERVICES_URL}/api/v1/dealers?postal-code=${postalCode}`);
  return response.data;
};

export const fetchDealerByPaCode = async (paCode: string): Promise<Dealer> => {
  const response = await postWithApiKey(`${SERVICES_URL}/api/v1/dealers/pa-code`, {
    paCode: paCode
  });
  return response.data;
};

export const fetchDealerById = async (dealerId: string): Promise<Dealer> => {
  const response = await getWithApiKey(`${SERVICES_URL}/api/v1/dealers/${dealerId}`);
  return response.data;
};
