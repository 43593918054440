import { EditableButton, EditableRichTextEditor } from '@fcamna/aem-library';
import { Divider, Image } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';

import protectedSvg from '../assets/images/info-protected.svg';
import ApplicationFormStepper from '../components/apply/applicationFormStepper';
import TeAlert from '../components/common/TeAlert';
import useCocaStore from '../store';
import AEMRoute from '../utils/aem/aemRoute';

const { CaretLeft } = fordProIcons;

export default function Apply() {
  const updateRootPage = useCocaStore((state) => state.updateRootPage);

  const handleApplyRoute = () => {
    updateRootPage('/home');
    window.scrollTo({ top: 0 });
  };
  return (
    <AEMRoute route={`${process.env.AEM_APPLY_PAGE}`}>
      <div className="layout-container">
        <EditableButton
          className="cancel-btn"
          onClick={handleApplyRoute}
          name="cancel-btn"
          leftIcon={<CaretLeft size="s" />}
        />
        <div data-testid="cocaForm">
          <EditableRichTextEditor name="main-heading" />
          <div className="divider">
            <Divider />
          </div>
          <EditableRichTextEditor
            className="rich-editor font-xsmall mt-8"
            name="main-para"
          />
          <TeAlert />
          <div className="d-flex align-center mt-4 gap-x-1">
            <div className="protected-svg">
              <Image
                srcProp={{ url: protectedSvg, alt: 'protected' }}
                className="pr-2"
              />
            </div>
            <EditableRichTextEditor
              name="trusted-text"
              className="rich-editor font-xsmall"
            />
          </div>
          <ApplicationFormStepper name="coca-tracker" />
        </div>
      </div>
    </AEMRoute>
  );
}
