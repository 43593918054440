import { Divider, Grid } from '@fcamna/react-library';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../../utils/aem/aemRoute';

export default function SelectVehicleReadOnly() {
  const isMobile = false;
  const { getValues, control } = useFormContext<SubmitApplicationFormData>();
  const { fields } = useFieldArray({ name: 'vehicles', control });
  const isExpress = getValues('isExpress');

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_SELECT_VEHICLE_READONLY}`}>
      <div className="read-only-wrapper">
        {isExpress ? (
          <div>
            <Grid className="gap-y-2">
              <Grid.Item
                s={12}
                m={6}
                l={3}>
                <div className="font-weight-bold font-xsmall my-2">Total Quantity</div>
                <div className="font-xsmall my-2">{getValues(`quantity`)}</div>
              </Grid.Item>
            </Grid>
          </div>
        ) : (
          <>
            {fields.map((field, index) => (
              <div
                key={field.id}
                style={{ paddingBottom: fields.length > 1 && fields.length - 1 != index ? 2 : 0 }}>
                <Grid className="gap-y-2">
                  <Grid.Item
                    s={12}
                    m={6}
                    l={3}>
                    <div className="font-weight-bold font-xsmall my-2">Year</div>
                    <div className="font-xsmall my-2">{getValues(`vehicles.${index}.year.label`)}</div>
                  </Grid.Item>
                  <Grid.Item
                    s={12}
                    m={6}
                    l={3}>
                    <div className="font-weight-bold font-xsmall my-2">Model</div>
                    <div className="font-xsmall my-2">{getValues(`vehicles.${index}.model.label`)}</div>
                  </Grid.Item>
                  <Grid.Item
                    s={12}
                    m={6}
                    l={3}>
                    <div className="font-weight-bold font-xsmall my-2">Trim</div>
                    <div className="font-xsmall my-2">{getValues(`vehicles.${index}.trim.label`)}</div>
                  </Grid.Item>
                </Grid>
                {isMobile && fields.length > 1 && fields.length - 1 != index && <Divider style={{ paddingBottom: 2 }} />}
              </div>
            ))}
          </>
        )}
      </div>
    </AEMSubRoute>
  );
}
