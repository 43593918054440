import { Card, Grid } from '@fcamna/react-library';

import { Page } from '../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import SensitiveContent from '../../common/SensitiveContent';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';

interface Props {
  applicantInformation: SubmitApplicationFormData['applicantInformation'];
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

function ContactReview(props: Readonly<Props>) {
  const { applicantInformation, handleModify, updateStep, setUpdatedStep } = props;
  const formattedName = () => {
    const withMiddleName = `${applicantInformation?.firstName} ${applicantInformation?.middleName} ${applicantInformation?.lastName}`;
    const withoutMiddleName = `${applicantInformation?.firstName} ${applicantInformation?.lastName}`;
    return undefined === applicantInformation?.middleName ? withoutMiddleName : withMiddleName;
  };

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title="Contact Information"
        handleModify={() => {
          handleModify(Page.CONTACT);
          setUpdatedStep(Page.CONTACT);
        }}
        currentUpdatedStep={updateStep === Page.CONTACT}
      />
      <Grid className="mt-3 gap-y-2 color-neutral-600">
        <Grid>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Name</div>
            <SensitiveContent className="font-xsmall my-2">{formattedName()}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Mobile Number</div>
            <SensitiveContent className="font-xsmall my-2">{applicantInformation?.phoneNumber}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Email Address</div>
            <SensitiveContent className="font-xsmall my-2">{applicantInformation?.emailAddress}</SensitiveContent>
          </Grid.Item>
        </Grid>
      </Grid>
      <ReviewFooter
        handleModify={() => {
          handleModify(Page.CONTACT);
          setUpdatedStep(Page.CONTACT);
        }}
      />
    </Card>
  );
}

export default ContactReview;
