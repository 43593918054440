import { Model, ModelManager } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useState } from 'react';

import { getSessionStorage } from '../jsUtils';
import { CustomModelClient } from './CustomModelClient';

const AEMCache: any = {};
const basePath = process.env.AEM_BASE_PATH;

const parseModelJSON = (pagePath: string) => {
  const res = ModelManager.modelStore.getData(pagePath);
  const modelParsed = res?.[':items']?.['root']?.[':items']?.['responsivegrid']?.[':items'];
  return modelParsed;
};

export const useAEMBasePath = (): string => {
  const isOverride = getSessionStorage('AEM_LOCALE') as string;
  if (isOverride) {
    return basePath ?? '';
  }

  const aemBasePath = basePath ?? '';
  return aemBasePath;
};

export const useRootModel = (): { model: Model | undefined } => {
  const [model, setModel] = useState<Model | undefined>();
  const basePath = useAEMBasePath();

  useEffect(() => {
    try {
      if (!model && basePath) {
        if (AEMCache[basePath]) {
          setModel(AEMCache[basePath]);
          return;
        }

        ModelManager.initialize({
          path: `${basePath}`,
          modelClient: new CustomModelClient(process.env.AEM_PUBLISHER_URL, process.env.AEM_USER, process.env.AEM_PASS)
        }).then((md: Model) => {
          setModel(md);
          AEMCache[basePath] = md;
        });
      }
    } catch (error) {
      console.log('AEM model failed to load');
    }
  }, [basePath, model]);

  return { model };
};

export const useModel = ({ pagePath = '' }: { pagePath?: string }): { parsedModel?: Record<string, any> } => {
  const basePath = useAEMBasePath();
  const [parsedData, setParsedData] = useState<any>();

  useEffect(() => {
    try {
      setParsedData(parseModelJSON(`${basePath}${'/apply'}`));
    } catch (error) {
      console.log('Failed to get model data ', pagePath, error);
    }
  }, []);

  return { parsedModel: parsedData };
};
