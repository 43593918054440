import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableInLineAlert, EditableRichTextEditor } from '@fcamna/aem-library';
import { Divider, Grid, InLineAlert, Link, Switch } from '@fcamna/react-library';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MSRP_LIMITS } from '../../../constants/vehicle.constant';
import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import useVehicleMSRPCalculator from '../../../hooks/useVehicleMSRPCalculator';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import useCocaStore from '../../../store';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import ButtonGroup, { ButtonNameValue } from '../../common/Buttons/ButtonGroup/ButtonGroup';
import CurrencyInput from '../../common/CurrencyInput';
import DetailedVehicle from './DetailedVehicle';
import ExpressVehicle from './ExpressVehicle';
import styles from './SelectVehicle.module.scss';

export default function SelectVehicle() {
  const isAuthorView = AuthoringUtils.isInEditor();
  const isExpress = useWatch({ name: 'isExpress' });
  const quantity = useWatch({ name: 'quantity' });
  const hasLineOfCredit = useWatch({ name: 'hasClocCredit' });
  const { setValue, trigger, clearErrors } = useFormContext<SubmitApplicationFormData>();
  const { totalVehicles, showCLOCContent, totalMSRP } = useVehicleMSRPCalculator();
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);
  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:select vehicles`);

  useEffect(() => {
    if (showCLOCContent) {
      clearErrors();
    }
  }, [clearErrors, showCLOCContent]);

  const isMaxMSRPLimitReached = () => {
    return totalMSRP > MSRP_LIMITS.PILOT_DEALER_MAX_LIMIT;
  };

  const clocOptions: ButtonNameValue<boolean>[] = [
    {
      aemName: 'cloc-yes',
      label: 'Yes',
      value: true
    },
    {
      aemName: 'cloc-no',
      label: 'No',
      value: false
    }
  ];

  const getTotalVehicleContent = () => {
    return (
      <>
        <div>
          <div className={styles.totalQuantityWrapper}>
            <EditableRichTextEditor
              name="total-vehicle-text"
              className="mb-2"
            />
            <span>{isExpress ? quantity : totalVehicles ?? 1}</span>
          </div>
          <EditableRichTextEditor
            name="total-vehicle-description"
            className="secondary-text-editor"
          />
        </div>
        {(showCLOCContent || isAuthorView) && getCLOCContent()}
      </>
    );
  };

  const getCLOCContent = () => {
    return (
      <div>
        <div className="divider">
          <Divider />
        </div>
        <EditableRichTextEditor name="cloc-text-description" />
        <div className="cf-field">
          <ButtonGroup
            name="hasClocCredit"
            aemName="has-cloc-credit"
            buttonProps={clocOptions}
            onClick={(value) => {
              setValue('hasClocCredit', { label: value.label, value: value.value });
              trigger('hasClocCredit');
            }}
          />
        </div>
        {(hasLineOfCredit?.value === true || isAuthorView) && (
          <EditableInLineAlert
            name="line-of-credit-yes"
            className={styles.clocAlertYes}
          />
        )}
        {(hasLineOfCredit?.value === false || isAuthorView) && (
          <InLineAlert
            variant="information"
            titleProp={{
              title: (
                <div>
                  <EditableRichTextEditor
                    name="cloc-alert-no-title"
                    className={styles.clocAlertNo}
                  />
                </div>
              )
            }}
            body={
              <span>
                <EditableRichTextEditor
                  name="cloc-alert-no-content"
                  className={styles.clocAlertNo}
                />
              </span>
            }
          />
        )}
      </div>
    );
  };

  const getDownPayment = () => {
    return (
      <div>
        <Grid>
          <Grid.Item
            xl={4}
            l={4}
            m={8}
            s={12}>
            <div className="cf-field">
              <CurrencyInput
                type="text"
                aemName="down-payment"
                name={'downPayment'}
                placeholder="00.00"
                className={styles.downPaymentText}
              />
            </div>
          </Grid.Item>
        </Grid>
      </div>
    );
  };

  const getDetailedVehicleAlerts = () => {
    return (
      <div className="my-6 d-flex flex-column gap-4">
        {(totalVehicles >= 10 || isAuthorView) && (
          <div className="w-100">
            <EditableInLineAlert
              name="detail-max-vehicle-text"
              className={styles.maxQuantityAlert}
            />
          </div>
        )}
        {(isMaxMSRPLimitReached() || isAuthorView) && (
          <div className="w-100">
            <EditableInLineAlert
              name="detail-max-msrp-text"
              className={styles.maxQuantityAlert}
            />
          </div>
        )}
      </div>
    );
  };
  const getPilotDealerDetails = () => {
    return (
      <>
        <div className={isExpress || isAuthorView ? '' : styles.disableExpressVehicle}>
          <ExpressVehicle />
        </div>
        <div className={styles.toggleContainer}>
          <Switch
            isChecked={!isExpress}
            onChange={() => setValue('isExpress', !isExpress)}
            size="medium"
            accessibilityOptions={{ ariaLabel: 'notification' }}
          />
          <EditableRichTextEditor name="toggle-text" />
        </div>
        {(!isExpress || isAuthorView) && (
          <>
            <DetailedVehicle
              totalVehicles={totalVehicles}
              totalMSRP={totalMSRP}
            />
            {getDetailedVehicleAlerts()}
          </>
        )}

        {getTotalVehicleContent()}
      </>
    );
  };
  const getNonPilotDealerDetails = () => {
    return (
      <>
        <DetailedVehicle
          totalVehicles={totalVehicles}
          totalMSRP={totalMSRP}
        />
        {totalVehicles > 1 && MSRP_LIMITS.NON_PILOT_DEALER_MAX_LIMIT > totalMSRP && (
          <div className="w-100">
            <div className="font-xsmall color-neutral">
              You have selected {totalVehicles} vehicles. <br />
              Each vehicle selection will constitute its own separate credit application. The information you provide in the following steps
              will be applied to each separate credit application.
            </div>
          </div>
        )}
        {totalMSRP > MSRP_LIMITS.NON_PILOT_DEALER_LIMIT && (
          <div className="w-100 mt-4">
            <InLineAlert
              body={
                totalMSRP > MSRP_LIMITS.NON_PILOT_DEALER_MAX_LIMIT ? (
                  <div>
                    You have exceeded the [maximum number of vehicles/maximum MSRP] for this online process. Please remove vehicle(s) in
                    order to continue, or click below to apply for a Commercial Line of Credit, which may better accommodate your needs.
                  </div>
                ) : (
                  <div>
                    Based on how many vehicles you plan to Finance; you may qualify for a Commercial Line of Credit.{' '}
                    <Link
                      to={process.env.CLOC_URL ?? '#'}
                      className="font-weight-bold">
                      Learn More and Apply Here
                    </Link>
                  </div>
                )
              }
              variant="information"
            />
          </div>
        )}
      </>
    );
  };

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_SELECT_VEHICLE}`}>
      {isAuthorView && getPilotDealerDetails()}
      {isPilotDealer ? getPilotDealerDetails() : getNonPilotDealerDetails()}
      {getDownPayment()}
    </AEMSubRoute>
  );
}
