import { Spinner } from '@fcamna/react-library';
import { Suspense } from 'react';

import businessInformation from '../components/apply/businessInformation';
import contactInformation from '../components/apply/contactInformation';
import ContactInformationReadOnly from '../components/apply/contactInformation/ContactInformationReadOnly';
import dealer from '../components/apply/dealer';
import DealerReadOnly from '../components/apply/dealer/DealerReadOnly';
import guarantorInformation from '../components/apply/guarantorInformation';
import selectVehicle from '../components/apply/selectVehicle';
import SelectVehicleReadOnly from '../components/apply/selectVehicle/SelectVehicleReadOnly';
import Apply from '../pages/Apply';
import Confirmation from '../pages/Confirmation';
import Home from '../pages/Home';
import Review from '../pages/Review';

/**
 * Keep minimal code on first load, lazy load internal steps.
 */
const PATH_MAP = {
  [process.env.AEM_HOME_PAGE as string]: Home,
  [process.env.AEM_APPLY_PAGE as string]: Apply,
  [process.env.AEM_REVIEW as string]: Review,
  [process.env.AEM_CONFIRMATION as string]: Confirmation,
  [process.env.AEM_SELECT_VEHICLE as string]: selectVehicle,
  [process.env.AEM_DEALER as string]: dealer,
  [process.env.AEM_CONTACT_INFORMATION as string]: contactInformation,
  [process.env.AEM_BUSINESS_INFORMATION as string]: businessInformation,
  [process.env.AEM_GUARANTOR_INFORMATION as string]: guarantorInformation,
  [process.env.AEM_SELECT_VEHICLE_READONLY as string]: SelectVehicleReadOnly,
  [process.env.AEM_DEALER_READONLY as string]: DealerReadOnly,
  [process.env.AEM_CONTACT_INFORMATION_READONLY as string]: ContactInformationReadOnly,
  [process.env.AEM_SELECT_VEHICLE_READONLY as string]: SelectVehicleReadOnly
};

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '24px'
      }}>
      <Spinner />
    </div>
  );
};

const CompLoader = ({ path }: { path: string }) => {
  const Comp = PATH_MAP[path];

  return (
    Comp && (
      <Suspense fallback={<Loader />}>
        <Comp />
      </Suspense>
    )
  );
};

export default CompLoader;
