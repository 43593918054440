import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { stateOptions } from '../../../../utils/StateData';
import AEMInput from '../../../common/AEM/Input/AEMInput';
import SelectInput from '../../../common/SelectInput';

export default function VehicleGarageAddressInfo() {
  const { trigger } = useFormContext<SubmitApplicationFormData>();

  return (
    <div>
      <Grid>
        <Grid.Item
          xl={6}
          l={6}
          m={10}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'businessInfo.vehicleGarageAddress.streetAddress'}
              aemName="vehicle-garage-street"
            />
          </div>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item
          xl={4}
          l={4}
          m={8}
          s={12}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'businessInfo.vehicleGarageAddress.city'}
              aemName="vehicle-garage-city"
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={2}
          l={2}
          m={3}
          s={5}>
          <div className="cf-field">
            <SelectInput
              name={'businessInfo.vehicleGarageAddress.state'}
              label={'State'}
              placeholder="Select..."
              options={stateOptions}
              onChange={() => {
                trigger('businessInfo.vehicleGarageAddress.state');
              }}
              isDefaultSelect={true}
            />
          </div>
        </Grid.Item>
        <Grid.Item
          xl={2}
          l={3}
          m={6}
          s={7}>
          <div className="cf-field">
            <AEMInput
              type="text"
              name={'businessInfo.vehicleGarageAddress.zip'}
              aemName="vehicle-garage-zip-code"
              mask="_____"
            />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
}
