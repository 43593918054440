import '../styles/global.scss';
import '../styles/utils.scss';

import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect } from 'react';

import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import useDealer from '../hooks/useDealer';
import useCocaStore from '../store';
import { AEMRoot } from '../utils/aem/aemRoute';
import { getAEMPath } from '../utils/jsUtils';
import { SessionStorageUtils } from '../utils/SessionStorageUtils';
import CompLoader from './AEMCompLoader';
import Layout from './Layout';
const FORD_PRO_URL = 'fordpro.com';
const isDealerURL = !window.location?.hostname?.includes(FORD_PRO_URL);

const getAEMPathAuthorView = () => {
  const aemPageName = window?.location?.pathname?.split('/').slice(-1)?.[0]?.replace(/.html/g, '');
  return getAEMPath('', `/${aemPageName}`);
};

const RootComp = ({ path, isAuthorView }: { path: string; isAuthorView: boolean }) => {
  return (
    <AEMRoot>
      {isDealerURL && !isAuthorView && <Header />}
      <Layout>
        <CompLoader path={path} />
      </Layout>
      {isDealerURL && !isAuthorView && <Footer />}
    </AEMRoot>
  );
};

export function App() {
  useDealer();
  const rootPath = useCocaStore((state) => state.rootPage);
  const updateRootPage = useCocaStore((state) => state.updateRootPage);
  const isAuthorView = AuthoringUtils.isInEditor();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const element = document.getElementById('CTA_LINK_Locate Dealer & Service');
      if (element != null && isDealerURL) {
        element.style.display = 'none';
        observer.disconnect();
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const currentPage = SessionStorageUtils.getItem('currentPage');
    currentPage && updateRootPage(currentPage);
  }, [updateRootPage]);

  if (isAuthorView) {
    return (
      <RootComp
        path={getAEMPathAuthorView()}
        isAuthorView={isAuthorView}
      />
    );
  }

  return (
    <RootComp
      path={rootPath}
      isAuthorView={isAuthorView}
    />
  );
}

export default App;
