import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { MSRP_LIMITS } from '../constants/vehicle.constant';
import { VehicleFormData } from '../models/formData/VehicleFormData';

const useVehicleMSRPCalculator = () => {
  const isExpress = useWatch({ name: 'isExpress' });
  const quantity = useWatch({ name: 'quantity' });
  const vehicles = useWatch({ name: 'vehicles' });

  const getTotalVehicles = (vehicleWatcher: VehicleFormData[]): number => {
    return vehicleWatcher?.reduce((total, vehicle) => {
      const { quantity = 1 } = vehicle;
      return total + quantity;
    }, 0);
  };

  const getTotalVehiclesMSRP = (vehicles: VehicleFormData[]) => {
    return vehicles?.reduce((total: number, vehicle: VehicleFormData) => {
      const { quantity = 1 } = vehicle;
      return total + (vehicle?.data?.price ?? 0) * quantity;
    }, 0);
  };

  const totalVehiclesAndMSRP = useMemo(() => {
    return {
      totalVehicles: getTotalVehicles(vehicles) ?? 0,
      totalMSRP: getTotalVehiclesMSRP(vehicles) ?? 0
    };
  }, [vehicles]);

  const showCLOCContent = useMemo(() => {
    return isExpress ? quantity >= 6 : getTotalVehicles(vehicles) >= 6 || getTotalVehiclesMSRP(vehicles) >= MSRP_LIMITS.PILOT_DEALER_LIMIT;
  }, [isExpress, quantity, vehicles]);

  return { showCLOCContent, totalVehicles: totalVehiclesAndMSRP.totalVehicles, totalMSRP: totalVehiclesAndMSRP.totalMSRP };
};

export default useVehicleMSRPCalculator;
