import { Button, Grid } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';

import useBreakpoint from '../../../../hooks/useBreakPoint';
import styles from '../../Review.module.scss';

const { Edit } = fordProIcons;

export interface ReviewFooterProps {
  handleModify: () => void;
}

function ReviewFooter(props: Readonly<ReviewFooterProps>) {
  const { handleModify } = props;
  const breakPoints = useBreakpoint();
  const isMobile = breakPoints?.xs;

  return (
    <Grid className={styles.cardheader}>
      {isMobile && (
        <Grid.Item
          s={12}
          m={6}
          l={6}
          xl={6}>
          <div className="w-100 d-flex justify-end mt-2">
            <Button
              onClick={() => handleModify()}
              variant="text"
              leftIcon={<Edit />}
              style={{ padding: 0 }}>
              Modify
            </Button>
          </div>
        </Grid.Item>
      )}
    </Grid>
  );
}

export default ReviewFooter;
