export enum Page {
  VEHICLE,
  DEALER,
  CONTACT,
  BUSINESS,
  GUARANTOR_INFORMATION,
  REVIEW
}

export type FormStepNames = 'vehicles' | 'dealer' | 'applicantInformation' | 'businessInfo' | 'guarantorInfo';
