import { Route, Routes } from 'react-router-dom';

import App from '../core/App';

export default function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={<App />}></Route>
      <Route
        path="/dealer/:dealerId"
        element={<App />}></Route>
      <Route
        path="/en-us/dealer/:dealerId"
        element={<App />}></Route>
      <Route
        path="*"
        element={<App />}
      />
    </Routes>
  );
}
