export const namePattern = new RegExp(/^[a-zA-Z]+(?:['\-. ][a-zA-Z ]?[a-zA-Z]+)*$/);

export const noInitialHyphenApostrophePattern = new RegExp(/^[^-']/);

export const noFinalHyphenApostrophePattern = new RegExp(/[^-']$/);

export const emailAddressPattern = new RegExp(
  /^([a-zA-Z0-9][\w.-]*\w@([a-zA-Z]|[a-zA-Z0-9][\w.-]*[a-zA-Z0-9])\.[a-zA-Z][a-zA-Z.]*[a-zA-Z])$/
);

export const phoneNumberPattern = new RegExp(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/);

export const formattedPhoneNumberPattern = new RegExp(/^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/);

export const zipCodePattern = new RegExp(/^\d{5}$/);

export const datePattern = new RegExp(/(0\d|1[0-2])\/([0-2]\d|3[0-1])\/(19|20)\d{2}/);

export const cityPattern = new RegExp(/^[^\d]*$/);

export const addressPattern = new RegExp(/^[a-zA-Z0-9 !@#&*()_[\]\\|;:'",./-]*$/);

/**
 * Formats pasted phone number with the supported phone number pattern.
 *
 * @param event
 * @returns
 */
export const formatPhoneNumber = (event: any): string => {
  const phoneNumber = event.clipboardData.getData('Text');
  if (formattedPhoneNumberPattern.test(phoneNumber)) {
    return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    return phoneNumber?.replace(/\D/g, '').trim();
  }
};
