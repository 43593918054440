export const CLOC_URL = process.env.CLOC_URL;
export const OCA_APPLY_PATH = '/apply';
export const OCA_REVIEW_PATH = '/review';
export const OCA_CONFIRMATION_PATH = '/confirmation';
export const TERMS_AND_CONDITIONS_FILE_PATH = '/assets/pdf/TermsAndCond.pdf';
export const GOGGLE_MAPS_URL = 'https://www.google.com/maps/place/';
export const CCPA_DELETE_MY_PI_URL = 'https://www.ford.com/help/privacy/ccpa/?ccpatype=deletemypi';
export const CCPA_DO_NOT_SELL_URL = 'https://www.ford.com/help/privacy/ccpa/?ccpatype=donotsellmypi';
export const SITE_MAP_URL = 'https://www.ford.com/help/sitemap/';
export const CUSTOMER_SUPPORT_CONTACT_URL = 'https://www.ford.com/finance/customer-support/#contact';
export const GLOSSARY_URL = 'https://www.ford.com/help/glossary/';
export const ACCESSIBILITY_URL = 'https://www.ford.com/help/contact/';
export const TERMS_AND_CONDITIONS_URL = 'https://www.ford.com/finance/terms-and-conditions/';
export const PRIVACY_URL = 'https://www.ford.com/help/privacy/';
export const AD_CHOICES_URL = 'https://info.evidon.com/pub_info/21090?v=1&nt=0&nw=false';
export const CA_PRIVACY_URL = 'https://www.ford.com/help/privacy/#caPrivacy';
export const ADOBE_PAGE_NAME_PREFIX = 'flmc:coca:credit app';
export const FORD_PRO_ADOBE_PAGE_NAME_PREFIX = 'fpro:financing:commercial online credit application';
