import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { SessionStorageUtils } from '../utils/SessionStorageUtils';

const initStore = (set: any) => ({
  homePath: '',
  rootPage: '/home',
  isPilotDealer: true,
  updatePilotDealer: (isPilotDealer: boolean) => {
    set({ isPilotDealer: isPilotDealer });
  },
  updateRootPage: (page: string) => {
    SessionStorageUtils.setItem('currentPage', page);
    window.scrollTo({ top: 0 });
    set({ rootPage: page });
  }
});

const useCocaStore = create(subscribeWithSelector(immer(initStore)));

export default useCocaStore;
