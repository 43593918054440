import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableRichTextEditor } from '@fcamna/aem-library';
import { useFormContext, useWatch } from 'react-hook-form';

import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import ButtonGroup from '../../common/Buttons/ButtonGroup';
import { ButtonNameValue } from '../../common/Buttons/ButtonGroup/ButtonGroup';
import EmploymentInformation from './EmploymentInformation/EmploymentInformation';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import ResidenceInformation from './ResidenceInformation/ResidenceInformation';

export default function GuarantorInformation() {
  const isAuthorView = AuthoringUtils.isInEditor();
  const { trigger, setValue } = useFormContext<SubmitApplicationFormData>();
  const hasGuarantorWatcher = useWatch({ name: 'hasGuarantor' });

  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:guarantor information`);

  const guarantorOptions: ButtonNameValue<boolean>[] = [
    {
      aemName: 'guarantor-yes',
      label: 'Yes',
      value: true
    },
    {
      aemName: 'guarantor-no',
      label: 'No',
      value: false
    }
  ];

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_GUARANTOR_INFORMATION}`}>
      <div>
        <div>
          <ButtonGroup
            name="hasGuarantor"
            aemName="add-guarantor"
            buttonProps={guarantorOptions}
            onClick={(value) => {
              setValue('hasGuarantor', { label: value.label, value: value.value });
              trigger('hasGuarantor');
            }}
            tooltipProps={{
              title: 'Guarantor',
              content: (
                <div>
                  A guarantor is a third party who agrees to be liable for your obligation in the event you default. It is not required at
                  this time, but it may be required later once your application(s) are evaluated by your selected dealer. By adding a
                  guarantor now, it may speed up the process.
                </div>
              )
            }}
          />
        </div>
        {(hasGuarantorWatcher?.value || isAuthorView) && (
          <>
            <div>
              <EditableRichTextEditor name="guarantor-personal-info-text" />
              <PersonalInformation />
            </div>
            <div>
              <EditableRichTextEditor
                name="guarantor-residence-info-text"
                className="my-4"
              />
              <ResidenceInformation />
            </div>
            <div>
              <EditableRichTextEditor
                name="guarantor-employment-info-text"
                className="my-4"
              />
              <EmploymentInformation />
            </div>
            <div>
              <EditableRichTextEditor
                name="guarantor-information-text"
                className="secondary-text-editor font-xsmall"
              />
            </div>
          </>
        )}
      </div>
    </AEMSubRoute>
  );
}
