import { generateRandomHex } from '../../utils/jsUtils';
import { DisplayModel } from '../DisplayModel';
import { LabelValue } from '../SubmitApplication';
import { Vehicle } from '../Vehicle';

export interface VehicleFormData {
  id: string | null;
  year: LabelValue<number> | undefined;
  model: LabelValue<string> | null;
  trim: LabelValue<string> | null;
  quantity: number | undefined;
  yearOptions: LabelValue<number>[] | [];
  modelOptions: DisplayModel[] | [];
  trimOptions: Record<string, Vehicle>;
  disabledTrimOptions: string[];
  data: Vehicle | undefined;
}

const defaultVehicle: VehicleFormData = {
  id: null,
  year: undefined,
  model: null,
  trim: null,
  quantity: 1,
  yearOptions: [],
  modelOptions: [],
  trimOptions: {},
  disabledTrimOptions: [],
  data: undefined
};

export const getDefaultVehicle = () => {
  defaultVehicle.id = generateRandomHex(16);
  return defaultVehicle;
};
