import { useEffect } from 'react';

import { trackAnalayticsOnLoad } from '../services/FordProAnalaytics';

const useAdobeAnalytics = (pageName: string, additionalData?: any) => {
  useEffect(() => {
    if (pageName !== '') {
      trackAnalayticsOnLoad(pageName, additionalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);
};

export default useAdobeAnalytics;
