import { EditableButton, EditableRichTextEditor } from '@fcamna/aem-library';
import { Divider, Grid } from '@fcamna/react-library';

import landingStore from '../assets/images/landing-1.jpg';
import landingInteraction from '../assets/images/landing-2.jpg';
import landingDelivery from '../assets/images/landing-3.jpg';
import styles from '../components/home/Home.module.scss';
import useCocaStore from '../store';
import AEMRoute from '../utils/aem/aemRoute';

export default function Home() {
  const updateRootPage = useCocaStore((state) => state.updateRootPage);

  const navigateToApplyPage = () => {
    updateRootPage('/apply');
  };

  return (
    <AEMRoute route={`${process.env.AEM_HOME_PAGE}`}>
      <div className={styles.container}>
        <div className="layout-container">
          <Grid
            gutterWidth={30}
            className={styles.gridStyles}>
            <Grid.Item
              s={12}
              m={12}
              l={4}
              xl={4}
              className={styles.gridItems}>
              <img
                src={landingStore}
                alt="Landing Store"
                className={styles.headerImage}
              />
            </Grid.Item>
            <Grid.Item
              s={12}
              m={12}
              l={4}
              xl={4}
              className={styles.gridItems}>
              <img
                src={landingInteraction}
                alt="Landing Interaction"
                className={styles.headerImage}
              />
            </Grid.Item>
            <Grid.Item
              s={12}
              m={12}
              l={4}
              xl={4}
              className={styles.gridItems}>
              <img
                src={landingDelivery}
                alt="Landing Delivery"
                className={styles.headerImage}
              />
            </Grid.Item>
          </Grid>
          <EditableRichTextEditor
            className={`rich-editor ${styles.applicationTitle}`}
            name="application-title"
          />
          <EditableRichTextEditor
            className={`rich-editor font-medium ${styles.applicationDescription}`}
            name="application-description"
          />
          <div className="my-10">
            <Divider />
          </div>
          <div className={styles.applicationSection}>
            <EditableRichTextEditor
              className={`rich-editor ${styles.applicationListDescription} font-small`}
              name="application-list-description-1"
            />
            <EditableRichTextEditor
              className={`rich-editor ${styles.applicationListDescription} font-small`}
              name="application-list-description-2"
            />
          </div>
          <EditableButton
            onClick={navigateToApplyPage}
            name="start-btn"
            className={styles.startAppBtn}
          />
        </div>
      </div>
    </AEMRoute>
  );
}
