import { NumberFormatValues } from 'react-number-format';
import { boolean, number, object, string } from 'yup';

import { addressPattern, cityPattern, zipCodePattern } from '../utils/regexPatterns';
import {
  dobSchema,
  emailAddressSchema,
  firstNameSchema,
  lastNameSchema,
  middleNameSchema,
  phoneNumberSchema,
  taxIdSchema,
  zipCodeSchema
} from '../utils/validationSchema';
import { ApplicationInfoFormData } from './formData/ApplicationInfoFormData';
import { BusinessInfoFormData } from './formData/BusinessInfoFormData';
import { DealerFormData } from './formData/DealerFormData';
import { GuarantorInfoFormData } from './formData/GuarantorInfoFormData';

export interface LabelValue<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export interface SubmitApplicationFormData {
  isDealerUrl: boolean;
  quantity: number;
  hasClocCredit: LabelValue<boolean>;
  downPayment: NumberFormatValues;
  dealer: DealerFormData;
  applicantInformation: ApplicationInfoFormData;
  businessInfo: BusinessInfoFormData;
  hasGuarantor: LabelValue<boolean>;
  guarantorInfo: GuarantorInfoFormData;
}

export const submitApplicationSchema = object({
  quantity: number().min(1, 'Quantity must be at least 1').max(10, 'Quantity cannot be more than 10').required('Quantity is required'),
  dealer: object({
    data: object({
      name: string().required('Dealer selection is required'),
      id: string().required('Dealer selection is required')
    }),
    zipCode: string().when('data', {
      is: undefined,
      then: (schema) => schema.matches(zipCodePattern, 'Zip Code must be 5 digits'),
      otherwise: (schema) => schema.notRequired()
    })
  }),
  applicantInformation: object({
    firstName: firstNameSchema(),
    lastName: lastNameSchema(),
    middleName: middleNameSchema(),
    phoneNumber: phoneNumberSchema(),
    emailAddress: emailAddressSchema()
  }),
  businessInfo: object({
    firstName: firstNameSchema(),
    lastName: lastNameSchema(),
    businessName: string().required('Business Legal Name is required').max(30, "You've reached the character limit"),
    enterpriseType: object({
      label: string(),
      value: string()
    })
      .default(null)
      .required('Enterprise Type is required'),
    yearsInBusiness: string().required('Years in Business is required').max(4, "You've exceeded the maximum amount"),
    streetAddress: string()
      .required('Business Street Address is required')
      .max(40, "You've reached the character limit")
      .matches(addressPattern, 'Address should contain only these !@#&*()_;:\'"/,.- characters'),
    city: string()
      .required('City is required')
      .max(30, "You've reached the character limit")
      .matches(cityPattern, 'City cannot have numbers'),
    state: object({
      label: string(),
      value: string()
    })
      .default(null)
      .required('State is required'),
    zip: zipCodeSchema(),
    phoneNumber: phoneNumberSchema('Business Phone Number'),
    taxId: taxIdSchema(),
    monthlyGrossProfit: object({
      floatValue: number().required('Monthly Gross Profit is required').max(999999, "You've exceeded the maximum amount")
    }),
    vehicleGarageAddress: object().when('hasVehicleGarageAddress', {
      is: true,
      then: () =>
        object({
          streetAddress: string()
            .required('Vehicle Garage Street Address is required')
            .max(40, "You've reached the character limit")
            .matches(addressPattern, 'Address should contain only these !@#&*()_;:\'"/,.- characters'),
          city: string()
            .required('City is required')
            .max(30, "You've reached the character limit")
            .matches(cityPattern, 'City cannot have numbers'),
          state: object({
            label: string(),
            value: string()
          })
            .default(null)
            .required('State is required'),
          zip: zipCodeSchema()
        }).required()
    })
  }),
  hasGuarantor: object({
    label: string(),
    value: boolean().required('Guarantor section is required')
  }).required(),
  guarantorInfo: object().when('hasGuarantor.value', {
    is: true,
    then: () =>
      object({
        personalInformation: object({
          firstName: firstNameSchema(),
          lastName: lastNameSchema(),
          dob: dobSchema(),
          ssn: string().default(null).required('SSN is required').min(11, 'SSN must be 9 digits'),
          email: emailAddressSchema(),
          phoneNumber: phoneNumberSchema()
        }).required(),
        residenceInformation: object({
          streetAddress: string()
            .required('Street Address is required')
            .max(40, "You've reached the character limit")
            .matches(addressPattern, 'Address should contain only these !@#&*()_;:\'"/,.- characters'),
          city: string()
            .required('City is required')
            .max(30, "You've reached the character limit")
            .matches(cityPattern, 'City cannot have numbers'),
          state: object({
            label: string(),
            value: string()
          })
            .default(null)
            .required('State is required'),
          zip: zipCodeSchema(),
          residenceType: object({
            label: string(),
            value: string()
          })
            .default(null)
            .required('Residence type is required'),
          rentMortgage: object({
            floatValue: number().required('Rent/Mortgage is required').max(9999, "You've exceeded the maximum amount")
          })
        }).required(),
        employmentInformation: object({
          employmentStatus: object({
            label: string(),
            value: string()
          })
            .default(null)
            .required('Employment status is required'),
          employmentType: object({
            label: string(),
            value: string()
          })
            .default(null)
            .required('Employment type is required'),
          employerName: string().required('Primary Employer name is required').max(40, "You've reached the character limit"),
          grossIncome: object({
            floatValue: number().required('Gross Income is required').max(99999, "You've exceeded the maximum amount")
          }),
          monthsOnJob: string().required('Months in Job is required').max(999, "You've exceeded the maximum Months"),
          incomeInterval: object({
            label: string(),
            value: string().required('Income Interval is required')
          }),
          occupation: string().required('Primary Occupation is required').max(20, "You've reached the character limit")
        }).required()
      }).required()
  }),
  hasClocCredit: object().when(['quantity'], ([quantity]) => {
    return quantity >= 6
      ? object({ label: string(), value: boolean().required('Commercial Line of Credit section is required') })
      : object().nullable();
  })
});
