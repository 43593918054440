import { Card, Grid } from '@fcamna/react-library';

import { Page } from '../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import useCocaStore from '../../../store';
import SensitiveContent from '../../common/SensitiveContent';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';

interface Props {
  businessInfo: SubmitApplicationFormData['businessInfo'];
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

function BusinessReview(props: Readonly<Props>) {
  const { businessInfo, handleModify, updateStep, setUpdatedStep } = props;
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);
  const BUSINESS_ADDRESS = `${businessInfo?.city}, ${businessInfo?.state.label} ${businessInfo?.zip}`;
  const GUARAGE_ADDRESS =
    businessInfo?.hasVehicleGarageAddress &&
    `${businessInfo?.vehicleGarageAddress?.city}, ${businessInfo?.vehicleGarageAddress?.state.label} ${businessInfo?.vehicleGarageAddress?.zip}`;

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title="Business Information"
        handleModify={() => {
          handleModify(Page.BUSINESS);
          setUpdatedStep(Page.BUSINESS);
        }}
        currentUpdatedStep={updateStep === Page.BUSINESS}
      />
      <Grid className="mt-3 gap-y-2 color-neutral-600">
        <Grid>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Principal Owner Name</div>
            <SensitiveContent className="font-xsmall my-2">
              {businessInfo?.firstName} {businessInfo?.lastName}
            </SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Business Phone</div>
            <SensitiveContent className="font-xsmall my-2">{businessInfo?.phoneNumber}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Trade/Business Name</div>
            <SensitiveContent className="font-xsmall my-2">{businessInfo?.businessName}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Enterprise Type</div>
            <div className="font-xsmall my-2">{businessInfo?.enterpriseType.label}</div>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Years in Business</div>
            <div className="font-xsmall my-2">{businessInfo?.yearsInBusiness}</div>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Business Address</div>
            <SensitiveContent className="font-xsmall mt-2">{businessInfo?.streetAddress}</SensitiveContent>
            <SensitiveContent className="font-xsmall mb-2">{BUSINESS_ADDRESS}</SensitiveContent>
          </Grid.Item>
          {isPilotDealer && (
            <Grid.Item
              xl={4}
              l={4}
              m={6}
              s={12}>
              <div className="font-weight-bold font-xsmall my-2">Vehicle Garaging Address</div>
              <SensitiveContent className="font-xsmall mt-2">
                {businessInfo?.hasVehicleGarageAddress ? businessInfo?.vehicleGarageAddress?.streetAddress : businessInfo?.streetAddress}
              </SensitiveContent>
              <SensitiveContent className="font-xsmall mb-2">
                {businessInfo?.hasVehicleGarageAddress ? GUARAGE_ADDRESS : BUSINESS_ADDRESS}
              </SensitiveContent>
            </Grid.Item>
          )}
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Tax ID</div>
            <SensitiveContent className="font-xsmall my-2">{businessInfo?.taxId}</SensitiveContent>
          </Grid.Item>
          <Grid.Item
            xl={4}
            l={4}
            m={6}
            s={12}>
            <div className="font-weight-bold font-xsmall my-2">Monthly Gross Profit</div>
            <SensitiveContent className="font-xsmall my-2">{businessInfo?.monthlyGrossProfit.formattedValue}</SensitiveContent>
          </Grid.Item>
        </Grid>
      </Grid>
      <ReviewFooter
        handleModify={() => {
          handleModify(Page.BUSINESS);
          setUpdatedStep(Page.BUSINESS);
        }}
      />
    </Card>
  );
}

export default BusinessReview;
