import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../../models/SubmitApplication';
import SensitiveContent from '../../../../common/SensitiveContent';

export default function EmploymentInformationReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();

  return (
    <div className="read-only-wrapper">
      <Grid className="gap-y-2">
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Employment Status</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.employmentInformation.employmentStatus.label')}
          </SensitiveContent>
        </Grid.Item>
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Employment Type</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.employmentInformation.employmentType.label')}
          </SensitiveContent>
        </Grid.Item>
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Primary Occupation</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.employmentInformation.occupation')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Primary Employer</div>
          <SensitiveContent className="font-xsmall my-2">{getValues('guarantorInfo.employmentInformation.employerName')}</SensitiveContent>
        </Grid.Item>
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Total Gross Income</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.employmentInformation.grossIncome.formattedValue')}
          </SensitiveContent>
        </Grid.Item>
        <Grid.Item
          l={3}
          m={6}
          s={12}>
          <div className="font-weight-bold font-xsmall my-2">Income Interval</div>
          <SensitiveContent className="font-xsmall my-2">
            {getValues('guarantorInfo.employmentInformation.incomeInterval.label')}
          </SensitiveContent>
        </Grid.Item>
      </Grid>
    </div>
  );
}
