import { EditableRichTextEditor } from '@fcamna/aem-library';
import { Button, Card, Checkbox, Grid } from '@fcamna/react-library';
import { useScroll } from '@react-hooks-library/core';
import { useEffect, useRef, useState } from 'react';

import tcPDF from '../../../assets/pdf/TermsAndCond.pdf';
import styles from '../Review.module.scss';

interface Props {
  scrollError: boolean;
  isAgreed: Agreements;
  handleCheckChanged: AgreementHandlers;
  hasGuarantor: boolean;
  isMultiVehicle: boolean;
}

export interface Agreements {
  applicant: number;
  guarantor?: number;
}

interface AgreementHandlers {
  applicant: (value: number) => void;
  guarantor?: (value: number) => void;
}

function TermsAndConditions(props: Readonly<Props>) {
  const box = useRef<HTMLDivElement | null>(null);
  const [scroll, setScroll] = useState({ x: 0, y: 0 });
  const [isReachTheEnd, setIsReachTheEnd] = useState<boolean>(false);
  const { isAgreed, handleCheckChanged, scrollError, hasGuarantor } = props;

  useScroll(box, ({ scrollX, scrollY }) => {
    setScroll({ x: scrollX, y: scrollY });
  });

  useEffect(() => {
    if (scroll && scroll.y >= 0.98) {
      setIsReachTheEnd(true);
    }
  }, [scroll, scroll.y]);

  const handlePrintTermsClick = () => {
    fetch(tcPDF)
      .then((response) => response.blob())
      .then((blob) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
          const pdfData = event?.target?.result as BlobPart;
          const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank', 'noopener,noreferrer');
        };

        fileReader.readAsArrayBuffer(blob);
      })
      .catch((error) => {
        console.error('Error loading PDF file:', error);
      });
  };

  return (
    <>
      <EditableRichTextEditor
        name="terms-and-conditions"
        className="mb-6"
      />
      <Grid className="mb-2">
        <Grid.Item
          xl={11}
          l={11}
          m={8}
          s={12}>
          <EditableRichTextEditor
            name="terms-desc"
            className={!scrollError ? styles.scrollNormal : styles.scrollErrorText}
          />
        </Grid.Item>
        <Grid.Item
          className={`d-flex justify-end`}
          xl={1}
          l={1}
          m={4}
          s={12}>
          <Button
            onClick={handlePrintTermsClick}
            variant="text"
            className={styles.printTermsBtn}>
            Print Terms
          </Button>
        </Grid.Item>
      </Grid>
      <Grid>
        <div className={styles.tcWrapper}>
          <Card
            cardType="default"
            orientation="vertical"
            ratio="16/9"
            className={styles.termsCard}
            titleProp={{
              headingLevel: undefined,
              title: '',
              size: undefined
            }}>
            <div>
              <Card.BodyContent className={styles.termsContent}>
                <div
                  data-testid="terms-and-cond"
                  ref={box}
                  className={styles.termsInnerBox}>
                  <EditableRichTextEditor name="terms-and-conditions-content" />
                </div>
              </Card.BodyContent>
            </div>
          </Card>
        </div>
      </Grid>
      <Grid className="mt-8">
        <div className={isReachTheEnd ? styles.checkboxLabel : styles.checkboxLabelDisabled}>
          <Grid>
            <Grid.Item
              xl={12}
              l={12}
              m={12}
              s={12}
              className={styles.termsCheckbox}>
              <Checkbox
                label="Applicant: I agree to the terms and conditions."
                value="applicant"
                isDisabled={!isReachTheEnd}
                checkboxValue={isAgreed.applicant}
                onChange={(_e, value) => {
                  handleCheckChanged.applicant(value);
                }}
              />
            </Grid.Item>
            {hasGuarantor && (
              <Grid.Item
                xl={12}
                l={12}
                m={12}
                s={12}
                className={styles.termsCheckbox}>
                <Checkbox
                  label="Guarantor: I agree to the terms and conditions."
                  value="guarantor"
                  isDisabled={!isReachTheEnd}
                  checkboxValue={isAgreed.guarantor}
                  onChange={(_e, value) => {
                    handleCheckChanged.guarantor && handleCheckChanged.guarantor(value);
                  }}
                />
              </Grid.Item>
            )}
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default TermsAndConditions;
