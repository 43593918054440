import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export function getWithApiKey<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
  return axios.get<T, R, D>(encodeURI(url), {
    ...config,
    headers: {
      ...config?.headers
    }
  });
}

export function postWithApiKey<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
  return axios.post<T, R, D>(encodeURI(url), data, {
    ...config,
    headers: {
      ...config?.headers
    }
  });
}
