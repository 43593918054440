import { IconButton } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useState } from 'react';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import SelectVehicleImageLoader from '../../../apply/selectVehicle/SelectVehicleImageLoader';
import styles from '../VehicleReview.module.scss';

const { CaretLeft, CaretRight } = fordProIcons;

interface Props {
  vehicleInformation: SubmitApplicationFormData['vehicles'];
}

export default function NonPilotDealerVehicleReview({ vehicleInformation }: Props) {
  const [activeVehicleIndex, setActiveVehicleIndex] = useState<number>(0);

  return (
    <div>
      <div className={styles.quantityText}>Qty of {vehicleInformation[activeVehicleIndex]?.quantity ?? 0}</div>
      <div className="color-neutral">
        <div className={styles.vehicleImageViewer}>
          <SelectVehicleImageLoader src={vehicleInformation[activeVehicleIndex]?.data?.usUrl ?? ''} />
          <div
            data-testid="vehicle-label"
            className="font-weight-bold font-small text-center">
            {vehicleInformation[activeVehicleIndex]?.year?.label} {vehicleInformation[activeVehicleIndex]?.data?.make}{' '}
            {vehicleInformation[activeVehicleIndex]?.model?.label}&nbsp;
            {vehicleInformation[activeVehicleIndex]?.trim?.label}
          </div>
        </div>
        <div className={styles.vehicleCarousel}>
          {vehicleInformation.length > 1 && (
            <IconButton
              icon={<CaretLeft />}
              onClick={() => setActiveVehicleIndex((index) => index - 1)}
              isDisabled={activeVehicleIndex === 0}
              title="Prev"
            />
          )}
          {activeVehicleIndex + 1 + ' of ' + vehicleInformation.length}
          {vehicleInformation.length > 1 && (
            <IconButton
              icon={<CaretRight />}
              onClick={() => setActiveVehicleIndex((index) => index + 1)}
              isDisabled={activeVehicleIndex === vehicleInformation.length - 1}
              title="Next"
            />
          )}
        </div>
      </div>
    </div>
  );
}
